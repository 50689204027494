
import { React, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { updateAnnouncementFunc } from '../states/storeState';
import { useDispatch } from 'react-redux';
import { notifyApprovedMailFunc, notifyRejectedMailFunc } from '../states/signinState';
import Form from 'react-bootstrap/Form';
import CardFavouritesAnnouncement from './CardFavouritesAnnouncement';
import escapeString from '../functions/escapeString';
import { useNavigate } from 'react-router';
import serverResponseManagement from '../functions/serverResponseManagement';


const CardPendingAnnouncement = ({ singleData, isLoading }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isRejecting, setIsRejecting] = useState(false);
    const [rejectionReasons, setRejectionReasons] = useState("");
    const [isHighlighted, setIsHighlighted] = useState(false);
    const token = localStorage.getItem("token");

    const emailPayload = {
        email: singleData[0].user.email,
        companyName: singleData[0].user.companyName,
        modelName: singleData[0].modelName,
        description: singleData[0].description,
        rejReasons: rejectionReasons ? escapeString(rejectionReasons) : ""
    }

    const notificationMail = (input) => {
        if (input === "approved") {
            dispatch(notifyApprovedMailFunc({ payload: emailPayload, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) { window.location.reload() }
                })
        }
        if (input === "rejected") {
            dispatch(notifyRejectedMailFunc({ payload: emailPayload, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) { window.location.reload() }
                })
        }

    }

    const approveAnnouncement = async () => {
        if (window.confirm("Do you want to approve this announcement? ")) {
            dispatch(updateAnnouncementFunc({ payload: { id: singleData[0].id, status: 1, dataApproved: new Date() }, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        notificationMail("approved")
                    }
                });
        }
    }

    const rejectAnnouncement = async () => {
        if (window.confirm("Do you want to reject this announcement? ")) {
            dispatch(updateAnnouncementFunc({ payload: { id: singleData[0].id, status: 3, rejReasons: escapeString(rejectionReasons), dataRejected: new Date() }, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        notificationMail("rejected")
                    }
                }); 
        }
    }


    return (

        <div className='position-relative'>
            {
                isRejecting ?
                    <div className='p-1 pt-5 mt-5 w-100 position-absolute d-flex justify-content-center align-items-center myZindex3'>
                        <div className='myMaxW1000 w-100 p-5 px-3 bg-dark rounded-4 border border-dark'>
                            <h6 className=' text-center mb-4 fw-light'>{singleData[0].modelName}</h6>
                            <h4 className='myLightGrayColor text-center mb-2 fw-light'>Reasons of rejection</h4>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" maxLength={10000} rows={10} onChange={(e) => setRejectionReasons(e.target.value)} />
                            </Form.Group>
                            <div className='d-flex justify-content-center gap-5'>
                                <i className="bi bi-ban text-danger myCursor" onClick={rejectAnnouncement}> Reject</i>
                                <i className="bi bi-arrow-return-left myLightGrayColor myCursor" onClick={() => { setIsRejecting(false); setIsHighlighted(false) }}> Cancel</i>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <div className={`border ${isHighlighted ? "border-warning" : "border-dark"} myMaxW632 myBgDark222 m-1`}>
                <CardFavouritesAnnouncement singleData={singleData[0]} isLoading={isLoading} idOwn={singleData[0].idOwner} superuser={true} />{
                    singleData[0].status === 0 ?
                        <div className='my-2 d-flex justify-content-center gap-5'>
                            {!singleData[0] || isLoading ? <Placeholder.Button xs={4} aria-hidden="true" /> : <i className="bi bi-check-circle myChatColor myCursor" onClick={approveAnnouncement}> Approve</i>}
                            {!singleData[0] || isLoading ? <Placeholder.Button xs={4} aria-hidden="true" /> : <i className="bi bi-ban text-danger myCursor" onClick={() => { setIsRejecting(true); setIsHighlighted(true) }}> Reject</i>}
                        </div>
                        : null
                }
            </div>
        </div>

    )
}

export default CardPendingAnnouncement