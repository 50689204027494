import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const TutorialHome = ({ step }) => {

    const [showStatusInfo, setShowStatusInfo] = useState({ bool: false, index: 0 });
    const info = [
        "The product is offline. You will receive a notification email as soon as our Team has reviewed it.",
        "The product is now online in the Market. You can send and receive chat messages. Your product can receive likes.",
        "The product is offline Other users cannot see your product anymore. You can set your product online again by clicking on the top-right corner airplane icon. Chat will not be deleted at any of these stages.",
        "The product is offline. You will receive a notification email with the reasons of rejection. Follow the instructions inside the email body to fix the problem and push the product into the review stage again."
    ]

    return (
        <>
            {
                step === 0 ?
                    <div className=' d-flex flex-column gap-5'>
                        <h2 className='logo'>Welcome to the Home <i className='bi bi-house-fill'></i></h2>
                        <h4> Here you can find all your products</h4>
                        <h4>
                            On the left side of your product image it's shown a colored vertical border which represent the status of your product.
                        </h4>
                        <div className='d-flex flex-wrap justify-content-center px-3'>
                            <h6 className='myMidGrayColor'>Click to show details</h6>
                            {
                                [...Array(4)].map((el, index) => {
                                    return <div className='w-100 my-2' key={`tutorialhome1-${index}`} onClick={() => { setShowStatusInfo({ bool: showStatusInfo.bool && showStatusInfo.index === index ? false : true, index }) }}>
                                        <div className=' d-flex align-items-center w-100 myCursor'
                                            style={{ height: "70px", borderLeft: `3px solid ${index === 0 ? "lightgray" : index === 1 ? "#90d800" : index === 2 ? "#f0bd35" : "red"}` }} ù
                                        >
                                            <div className='p-2'><i className="bi bi-card-image display-6"></i></div>
                                            <div className='myBgDark444 h-100 w-100 d-flex flex-column align-items-start ps-2'>
                                                <div className='w-75 bg-light mt-2' style={{ height: "4px" }}></div>
                                                <div className='w-25 bg-light mt-2' style={{ height: "4px" }}></div>
                                                <div className='w-50 bg-light mt-2' style={{ height: "4px" }}></div>
                                                {
                                                    index === 0 ?
                                                        <h6 className='mt-2'>On appoval <Spinner animation="grow" size="sm" /></h6>
                                                        : index === 1 ? <h6 className='mt-2 myChatColor'>Approved <i className='bi bi-check-circle-fill '></i></h6>
                                                            : index === 2 ? <h6 className='mt-2 myYellowColor'>Archived <i className='bi bi-archive-fill'></i></h6>
                                                                : <h6 className='mt-2 text-danger'>Rejected <i className="bi bi-ban "></i></h6>
                                                }
                                            </div>
                                        </div>
                                        {
                                            showStatusInfo.bool && showStatusInfo.index === index ?
                                                <div className='myBgDark444 p-2 text-start' style={{ borderTop: "1px solid #333", borderLeft: `3px solid ${index === 0 ? "lightgray" : index === 1 ? "#90d800" : index === 2 ? "#f0bd35" : "red"}` }}>
                                                    {info[index]}
                                                </div>
                                                : null
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    : step === 1 ?
                        <div className=' d-flex flex-column gap-5'>
                            <h2 className='logo'>Archived products </h2>
                            <h4>
                                <div className='d-flex mb-2 justify-content-center'>
                                    <div className='myBgDarkGray px-1 py-1 rounded-5 d-flex gap-1 myCursor'>
                                        <i className={`bi bi-house-fill px-3 py-1 rounded-5 myFontSize20px`} ></i>
                                        <i className={`bi bi-archive-fill myBgYellow text-dark px-3 py-1 rounded-5 myFontSize20px`} ></i>
                                    </div>
                                </div>
                                With this switch you can visualize archived products.
                            </h4>

                        </div>
                        :
                        <div className=' d-flex flex-column gap-5'>
                            <h2 className='logo'>Actions</h2>
                            <div className='d-flex align-items-center gap-3 ps-2'>
                                <h4><i className='bi bi-pencil-fill'></i></h4>
                                <h6 className='text-start'>Click on this icons, on the top-right corner of the product card, to <b>edit</b> it.</h6>
                            </div>
                            <div className='d-flex align-items-center gap-3 ps-2'>
                                <h4><i className='bi bi-trash-fill'></i></h4>
                                <h6 className='text-start'>Click on this icons, on the top-right corner of the product card, to set it <b>online</b> again.</h6>
                            </div>
                            <div className='d-flex align-items-center gap-3 ps-2'>
                                <h4><i className='bi bi-send-fill'></i></h4>
                                <h6 className='text-start'>Click on this icons, on the top-right corner of the product card, to <b>archive</b> it.</h6>
                            </div>
                        </div>
            }
        </>
    )
}

export default TutorialHome