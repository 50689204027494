import { React } from 'react';

const CardChatUser = ({ user, notify, newLikes }) => {

    return (
        <div className={`position-relative myBgDark222 d-flex gap-2 pe-2 mx-2`}>
            {
                newLikes && newLikes !== "null" && newLikes.split(",").includes(user.id.toString()) ?
                    <div className={`position-absolute w-100 h-100 myCursor d-flex align-items-center justify-content-end pe-4 gap-3 display-6 myZindex3 ${notify ? "myBgLikeAndChatNotify2" : "myBgLikeNotify2"}`}>
                        {notify ? <i className="bi bi-chat-dots-fill text-light"></i> : null}
                        <i className="bi bi-heart-fill text-light"></i>
                    </div>
                    : notify ?
                        <div className={`position-absolute w-100 h-100 myCursor d-flex align-items-center justify-content-end pe-4 display-6 myBgChatNotify myZindex3`}>
                            <i className="bi bi-chat-dots-fill text-light"></i>
                        </div>
                        : null

            }

            <div className='myMaxW65 d-flex justify-content-center p-2 bg-dark display-6' >
                <div className={`${user.manufacturer ? "myAquaColor" : "myChatColor"} py-2 px-2`} >
                    <div className='fw-bold'>{user.manufacturer ? "M" : "D"}</div>
                </div>
            </div>
            <div className='p-2'>
                <div className='d-flex gap-2'>
                    {
                        user.accountActive ?
                            <div className='d-flex align-items-baseline justify-content-center'><i className="bi bi-patch-check-fill myAquaColor"></i></div>
                            : null
                    }
                    <h5 className='line-clamp3'>{user.companyName}</h5>
                </div>
                <div className='d-flex gap-3 '>
                    {user.distributionArea ? <div><span>distribution area:</span> <span className='fw-bold'>{user.distributionArea}</span></div> : null}
                    <div><span>City:</span> <span className='fw-bold'>{user.city}</span></div>
                </div>
            </div>
        </div>
    )
}

export default CardChatUser