import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import serverErrorImg from '../assets/Graphics/serverError.png'

const ServerErrorPage = () => {
    return (
        <MainLayout>
            <div className='text-light text-center px-3'>
                <h1 className='pt-5'>Server Error</h1>
                <div className='d-flex justify-content-center pt-5'>
                    <img src={serverErrorImg} className='myMaxW100' alt="" />
                </div>
                <h5 className='mt-4'>We are working to solve the problem as soon as possible.</h5>
                <h3 className='mt-5'>Try later</h3>
            </div>
        </MainLayout>
    )
}

export default ServerErrorPage