import React, { useEffect, useState } from 'react';
import "../styles/accountCardBody.css";
import notificationAssigner from '../functions/notificationAssigner';
import { useDispatch, useSelector } from 'react-redux';
import { goToMyChat } from '../states/chatState';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { setSingleAnnouncement, setFavouritesData } from '../states/storeState';
import { setQuickActionOverlay } from '../states/generalState';
import removeLike from '../functions/removeLike';

const CardPenRejAnnouncementGrid = ({ idOwn, singleData, index }) => {

    const [notify, setNotify] = useState(false);
    const [renderHeight, setRenderHeight] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const targetRef = useRef();
    const actionRef = useRef();

    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const notifyCount = useSelector((state) => state.chat.notifyCount);
    const quickActionOverlay = useSelector((state) => state.general.quickActionOverlay);
    const outletUserData = useSelector((state) => state.outlet.outletUserData);
    const outletData = useSelector((state) => state.myStore.outletData);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);

    const [quickActions, setQuickActions] = useState(false);
    let blockQuickAction = false;
    const [iconOnFocus, setIconOnFocus] = useState(false);
    const [scrollYPos, setScrollYPos] = useState(localStorage.getItem("scrollY") ? localStorage.getItem("scrollY") : 0);

    const imgStyle = {
        width: "150px",
        marginBottom: "10px",
        gridRowEnd: `span ${renderHeight + 12}`
    }

    const noLikeAnymore = () => {
        if (singleData && outletUserData && outletUserData.length) {

            localStorage.setItem("scrollY", window.scrollY)
            removeLike(singleData, outletUserData, outletData, dispatch, navigate);

            const asyncFavouritesData = [];
            favouritesData && favouritesData.map((el) => {
                if (el.id !== singleData.id) {
                    asyncFavouritesData.push(el)
                }
            })
            dispatch(setFavouritesData(asyncFavouritesData));

        }
    };

    const blockAction = () => {
        if (!blockQuickAction) {
            blockQuickAction = true
        }
    };

    const triggerQuickAction = () => {

        blockQuickAction = false

        setTimeout(() => {
            if (window.location.pathname === "/favourites" && !blockQuickAction) {
                setQuickActions(true);
                dispatch(setQuickActionOverlay({ status: true, id: singleData.id }));
                actionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 700)
    };

    const shutAction = () => {
        setQuickActions(false)
    };

    useEffect(() => {
        if (quickActions) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 300)
        } else {
            document.body.style.overflow = 'scroll';
        }
    }, [quickActions])

    useEffect(() => {
        if (notificationAssigner({ allChatsNotify, idOwn, singleData })) {
            setNotify(true)
        }
    }, [allChatsNotify, notifyCount]);

    useEffect(() => {
        setRenderHeight(targetRef.current.clientHeight);
    }, []);

    useEffect(() => {
        if (!quickActionOverlay.status) {
            shutAction()
        }
    }, [quickActionOverlay]);

    useEffect(() => {
        if (quickActionOverlay.status) {
            dispatch(setQuickActionOverlay({ status: false, id: null }));
        }
    }, []);

    useEffect(() => {

        if (scrollYPos) {
            setTimeout(() => {
                window.scrollTo(0, scrollYPos);
                localStorage.removeItem("scrollY")
            }, 200)
        }

    }, [favouritesData]);

    return (
        <>
            {
                renderHeight ?
                    <div className={`position-relative rounded-5 shadow myCursor gridAnimation`} style={imgStyle} ref={actionRef}>

                        {quickActionOverlay.status ? <div className='position-absolute w-100 h-100 myZindex5 top-0' style={{ backgroundColor: "rgb(0,0,0,.8)" }} ></div> : null}
                        {
                            quickActions ?
                                <div className='position-absolute quickActionsMenu'>
                                    <div className='position-absolute w-100 px-4 py-3 myBgDark shadow rounded-5 d-flex align-items-center justify-content-between gap-3 text-light'>
                                        <div className='position-relative'>
                                            <i className={`bi bi-heart-fill quickActionsIcon myFucsiaRed ${iconOnFocus ? "myFucsiaRed" : ""}`} onClick={() => { navigate('/'); setIconOnFocus(true) }} onMouseLeave={() => { setIconOnFocus(false) }}></i>
                                            <i className="bi bi-slash-lg position-absolute" style={{ left: "1px", top: "-1px" }} onClick={noLikeAnymore}></i>
                                        </div>
                                        <i className='bi bi-chat-dots-fill quickActionsIcon' onClick={() => { dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })); navigate('/chat') }}></i>
                                    </div>
                                    <img className="w-100 rounded-5" ref={targetRef} src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]}`} alt="" />
                                </div>
                                : null
                        }

                        <div className={`overlayVerticalGrid rounded-5 d-flex gap-2 justify-content-center align-items-end pb-2 myFontSize07Em ${notify ? "myImgActiveRing" : ""} ${quickActionOverlay.status && quickActionOverlay.id === singleData.id ? " border" : ""}`}
                            onClick={() => { dispatch(setSingleAnnouncement(singleData)); navigate('/announcement') }}
                            onTouchStart={() => { triggerQuickAction() }}
                            onTouchMove={blockAction}
                        >
                            <i className="bi bi-eye-fill text-light "> {singleData.views}</i>
                            <i className="bi bi-suit-heart-fill myFucsiaRed" > {singleData.posClick}</i>
                            <i className={`myZindex2 bi bi-chat-dots-fill ${notify ? "myChatColor" : "text-light"} myCursor`} ></i>
                        </div>
                        <img className="w-100 rounded-5" ref={targetRef} src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]}`} alt="" />

                    </div>
                    :
                    <div className='position-relative rounded-5 shadow gridAnimation' style={{ ...imgStyle, zIndex: "-1" }}>
                        <img className='w-100 rounded-5' ref={targetRef} onLoad={(e) => { setRenderHeight(e.target.clientHeight) }} src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]}`} alt="" />
                    </div>
            }
        </>
    )
}

export default CardPenRejAnnouncementGrid