import { React, useEffect, useRef, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { createChatFunc, updateChatFunc, getSingleChatFunc, allUsersByIdSetFunc, updateAllChatsNotify, disableBackArrowChat, areThereNotify, setFavoritesAnnouncementsId } from '../states/chatState';
import CardChatUser from './CardChatUser';
import commaReplacer from '../functions/commaReplacer';
import commaInserter from '../functions/commaInserter';
import { updateAnnouncementFunc, changeAllData, setAllNewLikesCount, setSingleAnnouncement } from '../states/storeState';
import { updateUserOutlet, updateOutletUserData } from '../states/outletStore';
import decodeToken from '../functions/decodeToken';
import serverResponseManagement from '../functions/serverResponseManagement';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { setFeedId } from '../states/generalState';


const socket = io.connect(process.env.REACT_APP_SOCKETIO_SERVER);

const ChatAnnouncement = ({ singleData, isLoading, idOwn, width, outletUserData }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [conversation, setConversation] = useState([]);
    const [message, setMessage] = useState("");
    const [receivedMessage, setReceivedMessage] = useState("");
    const [sendededMessage, setSendedMessage] = useState("");
    const [pending, setPending] = useState(false);
    const [room, setRoom] = useState(//capisce se l'utente loggato è proprietario dell'annuncio e crea una room per ogni utente che ha messo like
        idOwn === singleData.idOwner ?
            singleData.likesArry ?
                singleData.likesArry.split(",").map((el) => {
                    return `${singleData.idOwner}$${singleData.id}$${el}`
                })
                : "nolikes"
            :
            `${singleData.idOwner}$${singleData.id}$${idOwn}`
    );
    const [isMyAnnouncement, setIsMyAnnouncement] = useState(typeof (room) === "object" ? true : false);
    const usersById = useSelector((state) => state.chat.usersById);
    const [allUserNotifyOrder, setAllUserNotifyOrder] = useState([...usersById].reverse());
    const allUserAnnouncements = useSelector((state) => state.myStore.allData);
    const loading = useSelector((state) => state.chat.loading);
    const singleChat = useSelector((state) => state.chat.singleChat);
    const allNewLikesCount = useSelector((state) => state.myStore.allNewLikesCount);
    const favoritesAnnouncementsId = useSelector((state) => state.chat.favoritesAnnouncementsId);
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const isChecked = isMyAnnouncement ? { ownerCheck: 1, roomCode: room } : { userCheck: 1, roomCode: room }
    const scrollRef = useRef();
    const scrollRefUsersCard = useRef();
    const myRef = useRef();
    const initialRoomSet = (idOwn === singleData.idOwner ? singleData.likesArry ? singleData.likesArry.split(",").map((el) => { return `${singleData.idOwner}$${singleData.id}$${el}` }) : "nolikes" : `${singleData.idOwner}$${singleData.id}$${idOwn}`)
    const [chatCreatedNow, setChatCreatedNow] = useState(false);//per evitare che venga eseguito il create 2 volte di seguito(sicurezza aggiuntiva, ma non necessario)
    const [iReadAllNewMssg, setIReadAllNewMssg] = useState(false);
    const [spuntaUnreadCount, setSpuntaUnreadCount] = useState(0);
    const [exactWidth, setExactWidth] = useState(0);
    let dataPivot = null;
    //token decode
    const token = localStorage.getItem("token");
    const tkn = decodeToken();


    const joinRoom = async () => {
        if (typeof (room) !== "object" && room !== "") {
            socket.emit("join_room", room)
        }
    };

    const leaveRoom = async () => {
        if (typeof (room) !== "object" && room !== "") {
            socket.emit("leave_room", room)
        }
    };

    const cleanArray = (input) => {
        const cleanedArray = [];
        if (input.length) {
            input.map((el) => {
                if (input && input !== undefined) {
                    cleanedArray.push(el)
                }
            })
        }
        return cleanedArray
    };

    const sendMessage = async (overlappingMessageInput) => {


        const mssg = {
            idAnn: singleData.id,
            idOwner: singleData.idOwner,
            idUser: +room.split("$")[2],
            roomCode: room,
            messages: `${isMyAnnouncement ? singleData.idOwner : +room.split("$")[2]}£${message}`,
            ownerCheck: 0,
            userCheck: 0
        }

        const conditions = singleData.id && singleData.idOwner && +room.split("$")[2] && room && message;
        if (!conditions) {
            window.location.reload()
        } else {

            if (typeof (room) !== "object") {

                const isChecked = isMyAnnouncement ? { ownerCheck: 1, userCheck: 0 } : { ownerCheck: 0, userCheck: 1 };
                setSpuntaUnreadCount(spuntaUnreadCount + 1);

                if (singleChat.length || chatCreatedNow) {
                    setPending(true);

                    const overlappedArray = [];
                    if ([...conversation].length) { overlappedArray.push(...conversation) };
                    if (receivedMessage) { overlappedArray.push(receivedMessage) };
                    if (mssg.messages) { overlappedArray.push(commaReplacer(mssg.messages)) };
                    const normalArray = [];
                    if ([...conversation].length) { normalArray.push(...conversation) };
                    if (mssg.messages) { normalArray.push(commaReplacer(mssg.messages)) };

                    const newMessagesCount = isMyAnnouncement ? { ownerLength: normalArray.length } : { userLength: normalArray.length }

                    dispatch(updateChatFunc({ payload: { roomCode: singleChat[0].roomCode, messages: mssg.messages, ...isChecked, ...newMessagesCount }, token }))
                        .then((res) => {
                            serverResponseManagement(res, navigate, dispatch);
                            if (res.payload && res.payload.statusCode === 200) {

                                if (!overlappingMessageInput) {
                                    setSendedMessage(`${mssg.messages}£${new Date().toString()}`); socket.emit("send_message", mssg)
                                };
                                setMessage("");
                                setPending(false);
                                reorganizeFavouritesChatOrder()
                            }
                        })


                } else {
                    setPending(true);
                    const newMessagesCount = isMyAnnouncement ? { ownerLength: 1, userLength: 0 } : { ownerLength: 0, userLength: 1 }

                    dispatch(createChatFunc({ payload: { ...mssg, messages: commaReplacer(mssg.messages), ...isChecked, ...newMessagesCount }, token }))
                        .then((res) => {
                            serverResponseManagement(res, navigate, dispatch);
                            if (res.payload && res.payload.statusCode === 200) {
                                if (!overlappingMessageInput) {
                                    setSendedMessage(`${mssg.messages}£${new Date().toString()}`);
                                    socket.emit("send_message", mssg)
                                };
                                dispatch(getSingleChatFunc({ room: room, token: token }))
                                    .then((res) => {
                                        serverResponseManagement(res, navigate, dispatch);
                                        if (res.payload && res.payload.statusCode === 200) {
                                            setMessage("");
                                            setPending(false);
                                            reorganizeFavouritesChatOrder();
                                        }
                                    })
                            }
                        });
                    setChatCreatedNow(true)
                }


            }

        }
    };

    const orderUserByNotify = (input) => {

        let notifyUser = [];
        let likeUser = [];
        let notNotifyUser = [];
        let notifyUserId = [];
        let notNotifyUserId = [];

        if (usersById && input) {

            let reverseUsersById = [...usersById].reverse();
            input && input.map((el) => {
                if (el.idOwner === tkn.id && el.idAnn === singleData.id) {
                    if (!el.ownerCheck) {
                        notifyUserId.push(el.idUser)
                    } else {
                        notNotifyUserId.push(el.idUser)
                    }
                }
            })
            reverseUsersById && reverseUsersById.map((item) => {
                if (notifyUserId.includes(item.id)) {
                    notifyUser.push({ ...item, notify: true });
                } else {

                    if (singleData.newLikes && singleData.newLikes !== "null") {

                        if (singleData.newLikes.split(",").includes(item.id.toString())) {
                            likeUser.push({ ...item, notify: false })
                        } else {
                            notNotifyUser.push({ ...item, notify: false });
                        }
                    } else {
                        notNotifyUser.push({ ...item, notify: false });
                    }
                }

            });
            const finalNotifyUserArray = notifyUser.concat(likeUser.concat(notNotifyUser));
            const partialFinalNotifyUserArray = notifyUser.concat(likeUser);
            const reorderedLikeArray = partialFinalNotifyUserArray.map((el) => { return el.id });

            if (usersById.length && notifyUser.length) {//se c'è una notifica
                dispatch(updateAnnouncementFunc({ payload: { id: singleData.id, likesArry: reorderedLikeArray.reverse(), reorderLikesArray: true }, token }))//aggiorna la posizione della lista degli utenti che hanno messo like
                    .then((res) => {
                        serverResponseManagement(res, navigate, dispatch);
                    })
            }
            setAllUserNotifyOrder(finalNotifyUserArray)

        }
    };

    const reorganizeFavouritesChatOrder = () => {

        if (favoritesAnnouncementsId && singleData.idOwner !== idOwn) {
            const newFavouritesIdOrder = []
            favoritesAnnouncementsId.map((el) => {
                if (+el !== singleData.id) {
                    newFavouritesIdOrder.push(el)
                }
            });
            newFavouritesIdOrder.unshift(singleData.id);

            dispatch(updateUserOutlet({ payload: { outletLikes: 0, reorderLikesReverse: true, activityId: singleData.id, id: outletUserData[0].id }, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch)
                });
            dispatch(updateOutletUserData([{ ...outletUserData[0], outletLikes: [...newFavouritesIdOrder].reverse().toString() }]));
            dispatch(setFavoritesAnnouncementsId(newFavouritesIdOrder));

        }
    };

    const updateChatNotifyAfterRead = () => {

        const updatedAllChatNotify = [];
        allChatsNotify.map((el) => {
            if (el.roomCode === room) {
                updatedAllChatNotify.push({ ...el, ...isChecked })
            } else {
                updatedAllChatNotify.push(el)
            }
        })
        dispatch(areThereNotify({ chats: updatedAllChatNotify, idOwner: tkn.id }));
        return updatedAllChatNotify
    };

    const updateChat = (input) => {
        dispatch(updateChatFunc({ payload: input, token }))
            .then((res) => {
                serverResponseManagement(res, navigate, dispatch);
                if (res.payload && res.payload.statusCode === 200) {
                    if (isMyAnnouncement) {
                        updateChatNotifyAfterRead()
                    } else {
                        dispatch(updateAllChatsNotify(updateChatNotifyAfterRead()))
                    }
                }
            })
    };

    const removeLikeNotificationFromAnnouncement = (input) => {
        const { singleData, user } = input;

        const oldLikesArray = singleData.newLikes && singleData.newLikes !== "null" ? singleData.newLikes : "";
        const newLikesArry = [];
        const newAllUserAnnouncements = [];
        allUserAnnouncements && allUserAnnouncements.length && allUserAnnouncements.map((item) => {
            if (item.id === singleData.id) {
                oldLikesArray && oldLikesArray.split(",").map((el) => {
                    if (el !== user.id.toString()) {
                        newLikesArry.push(el)
                    }
                });
                newAllUserAnnouncements.push({ ...item, newLikes: newLikesArry.toString() })
            } else {
                newAllUserAnnouncements.push(item)
            }
        });
        if (oldLikesArray && oldLikesArray.split(",").length > newLikesArry.length) {
            dispatch(changeAllData(newAllUserAnnouncements));
            dispatch(setAllNewLikesCount(allNewLikesCount - 1));
            dispatch(updateAnnouncementFunc({ payload: { id: singleData.id, newLikes: newLikesArry.toString() }, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch)
                })
        }
    };

    const timelineChatCalculation = (messageDate) => {

        const dateStringType = new Date(messageDate).toString();

        const dateNow = new Date().toString().split(" ").slice(0, 4).join(" ");
        const dataMessage = dateStringType.split(" ").slice(0, 4).join(" ");

        if (!dataPivot) {
            dataPivot = dataMessage === dateNow ? "Today" : dataMessage;
            return dataPivot
        } else {
            if (dataPivot === dataMessage || dataPivot === "Today") {
                return false
            } else {
                dataPivot = dataMessage === dateNow ? "Today" : dataMessage;
                return dataPivot
            }
        }
    };

    useEffect(() => {
        return () => {
            leaveRoom(room)
        };
    }, [room]);

    useEffect(() => {
        joinRoom();
        socket.on("receive_message", async (data) => {
            setReceivedMessage(data.messages);
        })
    }, [socket, room]);

    useEffect(() => {
        if (pending) {
            sendMessage(true);
        };
        receivedMessage && setConversation([...conversation, receivedMessage]);
    }, [receivedMessage]);

    useEffect(() => {

        if (typeof (room) !== "object" && singleChat && singleChat.length) {

            if (receivedMessage) { setIReadAllNewMssg(true); setSpuntaUnreadCount(0) }

            setTimeout(() => {
                const chatLength = singleChat[0].messages ? singleChat[0].messages.split(",").length : 0;
                let newMssgLength;
                if (receivedMessage) {
                    newMssgLength = { ownerLength: chatLength, userLength: chatLength }
                } else { newMssgLength = isMyAnnouncement ? { ownerLength: chatLength } : { userLength: chatLength } }
                updateChat({ ...isChecked, ...newMssgLength })
            }, 100)

        }
    }, [receivedMessage, singleChat]);

    useEffect(() => {
        sendededMessage && setConversation([...conversation, sendededMessage])
    }, [sendededMessage]);

    useEffect(() => {
        setMessage("");
        if (typeof (room) !== "object") {
            dispatch(getSingleChatFunc({ room: room, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        setSpuntaUnreadCount(Math.abs(res.payload.data[0].ownerLength - res.payload.data[0].userLength));

                        if (res.payload.data[0].messages) {
                            setConversation(res.payload.data[0].messages.split(","))
                        } else {
                            setConversation(res.payload.data[0].messages)
                        }
                    }
                })
        }
        if (typeof (room) === "object" && !usersById.length) {
            dispatch(allUsersByIdSetFunc({ idSet: singleData.likesArry, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch)
                })
        }
    }, [room]);

    useEffect(() => {
        setSpuntaUnreadCount(0);
        if (isMyAnnouncement && typeof (room) !== "object") {
            dispatch(disableBackArrowChat(true))
        } else {
            dispatch(disableBackArrowChat(false))
        }
    }, [room]);

    useEffect(() => {
        orderUserByNotify(allChatsNotify)
    }, [allChatsNotify, usersById]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [conversation]);

    useEffect(() => {
        window.scrollTo(0, 0);
        scrollRefUsersCard.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, []);

    useEffect(() => {
        setExactWidth(myRef.current.getBoundingClientRect().width)
    }, [])

    return (
        <div className='myVhChat d-flex flex-column justify-content-between' ref={myRef} style={{ width: `${width < 1100 ? "99vw" : "100%"}` }}>

            <div className='myBgDark222 position-fixed myZindex4 w-100'>
                {
                    isMyAnnouncement && typeof (room) !== "object" ?
                        <div className='position-relative d-flex justify-content-center pt-1' style={{ width: `${exactWidth}px` }}>
                            <div className='pb-3 d-flex align-items-center flex-column position-absolute ' >
                                {
                                    allUserNotifyOrder && allUserNotifyOrder.map((el, index) => {
                                        if (el.id === +room.split("$")[2])
                                            return (
                                                <div key={`chatannouncement1-${index}`} className='d-flex gap-2 align-items-center myCursor'>
                                                    <i className="bi bi-chevron-left display-6" onClick={() => { setConversation([]); dispatch(updateAllChatsNotify(updateChatNotifyAfterRead())); setRoom(initialRoomSet) }}></i>
                                                    <div className='pe-3' onClick={() => setRoom(`${singleData.idOwner}$${singleData.id}$${el.id}`)}>
                                                        <CardChatUser idAnn={singleData.id} user={el} notify={false} />
                                                    </div>
                                                </div>
                                            )
                                    })
                                }
                            </div>
                        </div>
                        :
                        <div className='myMaxW1000 d-flex align-items-center my-1 gap-3 p-1' >
                            <div className='d-flex align-items-center ms-5'>
                                {
                                    !singleData || isLoading ?
                                        <Placeholder className="imgChatMain" animation="glow"><Placeholder xs={12} style={{ height: "60px", borderRadius: "30px" }} /></Placeholder>
                                        :
                                        <Link to={'/announcement'}>
                                            <div className="myBgImgCover2 imgChatMain myCursor rounded-5"
                                                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]})` }}
                                                onClick={() => { dispatch(setSingleAnnouncement(singleData)) }}
                                            ></div>
                                        </Link>

                                }
                            </div>
                            <div className='mainInfo'>
                                <div className="line-clamp1 py-2 pe-5" >
                                    <h4 className='fw-light'>{singleData.modelName}</h4>
                                </div>
                                {
                                    !singleData || isLoading ?
                                        <Placeholder animation="glow"><Placeholder xs={4} /></Placeholder> :
                                        <div className='d-flex align-items-center gap-3'>
                                            {
                                                singleData.quantity > 0 ?
                                                    <div className='d-flex'>
                                                        <h6 className='fw-light bg-dark fw-bold text-light pe-2 rounded-5 noBreak'>{singleData.quantity}<b className='fw-light'>•items</b></h6>
                                                    </div>
                                                    : null
                                            }
                                            <div className='d-flex align-items-center brandName myWidth60Percent'>
                                                {
                                                    isMyAnnouncement ?
                                                        null
                                                        : <Link className="myYellowColor noDecoration fw-bold" to={'/feed'} onClick={() => { localStorage.removeItem("feedId"); dispatch(setFeedId(singleData.idOwner)) }}><p className='m-0 line-clamp3 '><i className="bi bi-person-circle"></i> {singleData.user.companyName}</p></Link>
                                                }
                                            </div>
                                        </div>
                                }
                                {
                                    !singleData || isLoading ?
                                        <Placeholder animation="glow"><Placeholder xs={4} /></Placeholder> :
                                        <div className='d-flex position-relative align-items-center w-100 py-1 gap-2 myOverFlowXHidden'>
                                            <div className='d-flex align-items-top pe-2'>
                                                <h4 className="fw-normal">{singleData.price > 0 ? (Math.floor((singleData.price) / (singleData.quantity))).toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0"}</h4>
                                                <p className='fw-normal m-0 me-1'>{singleData.price > 0 ? (Math.round((singleData.price) / (singleData.quantity) * 100) / 100).toString().split(".")[1] : null}</p>
                                                <p className='fw-normal m-0'>{singleData.currency ? singleData.currency.split(":")[1] : null}/item</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </div>


            <div className="pt-3 myOverflowY myBgDark w-100" style={{ flexGrow: "1" }}>

                {typeof (room) === "object" ? <div style={{ height: `${width >= 1100 ? "105px" : "85px"}` }}>{/* my spacer */}</div> : <div style={{ height: `${width >= 1100 ? "30px" : "20px"}` }}>{/* my spacer */}</div>}
                {

                    loading ?
                        <div className="loadingChatBar myZindex4"><div className='percentageBarChatGlow h-100 '></div></div>
                        :
                        typeof (room) === "object" ?
                            allUserNotifyOrder && allUserNotifyOrder.map((el, index) => {
                                return (
                                    <div key={`chatannouncement2-${index}`} ref={scrollRefUsersCard} className='mt-1 myCursor' onClick={() => { setRoom(`${singleData.idOwner}$${singleData.id}$${el.id}`); el.notify && updateChat({ ...isChecked, roomCode: `${singleData.idOwner}$${singleData.id}$${el.id}` }); removeLikeNotificationFromAnnouncement({ singleData, user: el }) }}>
                                        <CardChatUser newLikes={singleData.newLikes} user={el} notify={el.notify} />
                                    </div>
                                )
                            })
                            :
                            room === "nolikes" ?
                                <div className='d-flex align-items-center justify-content-center flex-column display-3 h-100'>
                                    <i className="bi bi-heartbreak-fill myFucsiaRed"></i>
                                    <h3 className='fw-light text-center'>This product has no Likes yet!</h3>
                                </div>
                                :
                                <div className={`${isMyAnnouncement ? "pt-5 mt-3" : "pt-5 mt-4"} `}>

                                    <ul className='p-0 m-0' style={{ listStyle: "none" }}>
                                        {
                                            singleChat && singleChat.length && !iReadAllNewMssg ?
                                                conversation && conversation.map((el, index) => {
                                                    if (el && el !== " ") {

                                                        const newMssgLenth = Math.abs(singleChat[0].ownerLength - singleChat[0].userLength);
                                                        const newMssgIndex = conversation.length - spuntaUnreadCount;

                                                        const iNeedToRead = () => {
                                                            if (isMyAnnouncement) {
                                                                if (singleChat[0].ownerLength < singleChat[0].userLength) {
                                                                    return true
                                                                } else { return false }
                                                            } else {
                                                                if (singleChat[0].userLength < singleChat[0].ownerLength) {
                                                                    return true
                                                                } else { return false }
                                                            }
                                                        }

                                                        const timeline = timelineChatCalculation(el.split("£")[2]);
                                                        const dateMessage = new Date(el.split("£")[2]).toString();

                                                        if (newMssgLenth && index === newMssgIndex && iNeedToRead()) {
                                                            return (
                                                                <div key={`chatannouncement3-${index}`}>
                                                                    <h6 className='text-center mt-4 mb-1'>{newMssgLenth} unread message{newMssgLenth > 1 ? "s" : ""}</h6>
                                                                    <li className={`px-3 pt-3 d-flex justify-content-${+el.split("£")[0] === idOwn ? "start" : "end"} ${index >= newMssgIndex ? "myBgDark444" : ""}`} ref={scrollRef}>
                                                                        <div className={`${+el.split("£")[0] === idOwn ? "bg-primary" : "bg-secondary"} rounded-4 p-3 px-4 m-2 text-light`}>
                                                                            <h5 className='fw-light'>{commaInserter(el.split("£")[1])}</h5>
                                                                            <div className='d-flex justify-content-end gap-4'>
                                                                                <p className='m-0 mt-1 mySoftGrayColor'>{dateMessage.split(" ")[4].split(":")[0]}:{dateMessage.split(" ")[4].split(":")[1]}</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <li key={`chatannouncement3-${index}`} className={`px-3 d-flex flex-wrap justify-content-${+el.split("£")[0] === idOwn ? "start" : "end"} ${(index >= newMssgIndex) && iNeedToRead() ? "myBgDark444" : ""}`} ref={scrollRef}>
                                                                    {timeline ? <h6 className='w-100 my-3 text-center border-bottom border-secondary fw-light mySoftGrayColor'>{timeline}</h6> : null}
                                                                    <div className={`${+el.split("£")[0] === idOwn ? "bg-primary" : "bg-secondary"} rounded-4 p-3 px-4 m-2 text-light`}>
                                                                        <h5 className='fw-light'>{commaInserter(el.split("£")[1])}</h5>
                                                                        <div className='d-flex justify-content-end gap-1'>
                                                                            <p className='m-0 mt-1 mySoftGrayColor'>{dateMessage.split(" ")[4].split(":")[0]}:{dateMessage.split(" ")[4].split(":")[1]}</p>
                                                                            {+el.split("£")[0] === idOwn ? <i className={`bi bi-check-all ${index >= newMssgIndex ? "mySoftGrayColor" : "myChatColor"}`}></i> : null}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    }
                                                })
                                                :
                                                singleChat && singleChat.length && conversation ? conversation.map((el, index) => {
                                                    if (el && el !== " ") {
                                                        const timeline = timelineChatCalculation(el.split("£")[2]);
                                                        const newMssgIndex = conversation.length - spuntaUnreadCount;

                                                        const dateMessage = el.split("£").length === 3 ? new Date(el.split("£")[2]).toString() : new Date().toString();

                                                        return (
                                                            <li key={`chatannouncement4-${index}`} className={`px-3 d-flex flex-wrap justify-content-${+el.split("£")[0] === idOwn ? "start" : "end"}`} ref={scrollRef}>
                                                                {timeline ? <h6 className='w-100 my-3 text-center border-bottom border-secondary fw-light mySoftGrayColor'>{timeline}</h6> : null}
                                                                <div className={`${+el.split("£")[0] === idOwn ? "bg-primary" : "bg-secondary"} rounded-4 p-3 px-4 m-2 text-light`}>
                                                                    <h5 className='fw-light'>{commaInserter(el.split("£")[1])}</h5>
                                                                    <div className='d-flex justify-content-end gap-1'>
                                                                        {<p className='m-0 mt-1 mySoftGrayColor'>{dateMessage.split(" ")[4].split(":")[0]}:{dateMessage.split(" ")[4].split(":")[1]}</p>}
                                                                        {+el.split("£")[0] === idOwn ? <i className={`bi bi-check-all ${index >= newMssgIndex ? "mySoftGrayColor" : "myChatColor"}`}></i> : null}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                })
                                                    : null
                                        }
                                    </ul>

                                </div>

                }
                {typeof (room) !== "object" ? <div style={{ height: "80px" }}>{/* my spacer */}</div> : null}
            </div >


            {
                typeof (room) === "object" || room === "nolikes" || loading ?
                    null
                    :
                    <div className='myBgDark222 position-fixed' style={{ bottom: "65px", width: `${exactWidth}px` }}>
                        <div className='mx-2 my-3 d-flex gap-2 align-items-center' ref={scrollRef}>
                            <input disabled={pending ? true : false} id='chat-input' placeholder='Write a message' className='w-100 rounded-5 myBgDarkGray border border-dark text-light p-1 px-3' type="text"
                                value={message}
                                onChange={(e) => { if (!e.target.value.includes("£")) { setMessage(e.target.value) } }}
                                onKeyDown={(e) => { if (e.key === "Enter" && message.trim()) { sendMessage(false) } }}
                                onClick={() => { setIReadAllNewMssg(true) }}
                            />
                            {message && !pending && message.trim() ? <i className="bi bi-arrow-right-circle-fill display-6 myChatColor myCursor" onClick={() => { sendMessage(false) }}></i> : null}
                        </div>
                    </div>
            }
        </div >
    )
}

export default ChatAnnouncement