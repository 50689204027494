import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import productCategories from '../assets/JSON/productCategories.json';
import { setCategoriesProduct, delCategoriesProduct, clearCategoriesProduct } from '../states/generalState';
import { updateAccountFunc } from '../states/signinState';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router';
import serverResponseManagement from '../functions/serverResponseManagement';

const CategoriesPreferences = ({ userData }) => {

    const categoriesProduct = useSelector((state) => state.general.categoriesProduct);
    const categoriesProductId = useSelector((state) => state.general.categoriesProductId);
    const isLoading = useSelector((state) => state.signin.loading);
    const [errorMessage, setErrorMessage] = useState("");
    const [isEditing, setIsEditing] = useState(false)
    const [zoomIn, setZoomIn] = useState({ field: "", value: false });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const manageCategory = (input) => {
        const { el, e } = input;
        if (categoriesProductId.length < 7 || categoriesProductId.includes(el.id)) {
            if (e.currentTarget.classList.toggle('borderActive')) {
                dispatch(setCategoriesProduct(el));
            } else {
                dispatch(delCategoriesProduct(el.id))
            }
        }
    };

    const handleUpdateAccount = async () => {
        if (categoriesProduct) {
            dispatch(updateAccountFunc({ payload: { ...userData, interests: categoriesProductId.length ? categoriesProductId.toString() : "clear" }, token: localStorage.getItem("token") }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        window.location.reload()
                    }
                });
        } else {
            setErrorMessage("Select at least one category")
        }

    };

    useEffect(() => {
        dispatch(clearCategoriesProduct())
        //serve per aggiungere le categorie che l'utente ha salvato nella sezione selected category/ies
        productCategories.map((el) => {
            if (userData.interests.split(",").includes(el.id.toString())) {
                dispatch(setCategoriesProduct(el))
            }
        })
    }, [])


    return (
        <div>
            <Form.Group className="mb-3 pb-5 myBgDark text-light">
                <div className='d-flex flex-wrap justify-content-center w-100 pt-5 pb-3'>
                    <div>
                        <h1 className={`d-flex justify-content-center fw-normal ${isEditing ? "mb-4" : ""}`}>Categories of interest</h1>
                        {isEditing ? <h6 className='px-3 fw-light text-center mb-4 myLightGrayColor'><i className="bi bi-info-circle-fill"></i> Choosing one or more categories of interests will increase the probability of finding products in the category/ies of interest/s. The change will require up to 24h to be applied. </h6> : null}
                        {isEditing ?
                            <div>
                                <h5 className='w-100 text-center mt-3'>Dental Categories</h5>
                                <hr className='w-100 px-5 mt-1' />
                                <div className='d-flex justify-content-center gap-3 mb-3 display-6'>
                                    {
                                        zoomIn.value && zoomIn.field === "den" ?
                                            <i className="bi bi-zoom-out myCursor" onClick={() => { setZoomIn({ field: "den", value: false }) }}></i>
                                            : <i className="bi bi-zoom-in myCursor" onClick={() => { setZoomIn({ field: "den", value: true }) }}></i>
                                    }
                                </div>
                                <div className={`${zoomIn.value && zoomIn.field === "den" ? "zoomInClass" : "normalZoom"} d-flex flex-wrap justify-content-center`}>
                                    {
                                        productCategories.map((el) => {
                                            if (el.area === "dental") {
                                                return (<span key={`categoriespreferences1${el.id}${el.code}`}
                                                    className={`m-1 px-2 text-center text-dark rounded-5 myBgAqua myCursor myBorderAcqua ${categoriesProductId.length && categoriesProductId.includes(el.id) && "borderActive"}`}
                                                    onClick={(e) => { manageCategory({ el, e }) }}>{el.eng}</span>)
                                            }
                                        })
                                    }
                                </div>
                                <h5 className='w-100 text-center mt-5'>Medical Categories</h5>
                                <hr className='w-100 px-5 mt-1' />
                                <div className='d-flex justify-content-center gap-3 mb-3 display-6'>
                                    {
                                        zoomIn.value && zoomIn.field === "med" ?
                                            <i className="bi bi-zoom-out myCursor" onClick={() => { setZoomIn({ field: "med", value: false }) }}></i>
                                            : <i className="bi bi-zoom-in myCursor" onClick={() => { setZoomIn({ field: "med", value: true }) }}></i>
                                    }
                                </div>
                                <div className={`${zoomIn.value && zoomIn.field === "med" ? "zoomInClass" : "normalZoom"} d-flex flex-wrap justify-content-center`}>
                                    {
                                        productCategories && productCategories.map((el) => {
                                            if (el.area === "medical") {
                                                return (<span key={`categoriespreferences2${el.id}${el.code}`}
                                                    className={`px-2 text-dark text-center m-1 rounded-5 myBgRed myCursor myBorderRed ${categoriesProductId.length && categoriesProductId.includes(el.id) && "borderActive"}`}
                                                    onClick={(e) => { manageCategory({ el, e }) }}>{el.eng}</span>)
                                            }
                                        })
                                    }
                                </div>
                                <hr className='w-100 px-5 mt-3' />
                            </div>
                            : null
                        }

                    </div>
                </div>

                {
                    categoriesProduct.length > 0 ?
                        <div className='myBgDark rounded-4 mt-1 p-2 mx-3'>
                            <h6 className='text-center text-light fw-light'>Selected category/ies:</h6>
                            <div className='d-flex flex-wrap justify-content-center my-3 normalZoom'>
                                {
                                    categoriesProduct && categoriesProduct.map((el) => {
                                        return <span key={`categoriespreferences3${el.id}${el.code}`} className={`text-dark p-1 px-3 m-1 rounded-5 d-flex align-iems-center ${el.area == "dental" ? "myBgAqua" : "myBgRed"}`}>{el.eng} </span>
                                    })
                                }
                            </div>
                        </div>
                        : null
                }

                {
                    errorMessage ?
                        <div className='d-flex justify-content-center mt-4'>
                            <div className='p-3 bg-warning text-secondary'>{errorMessage}</div>
                        </div>
                        : null
                }


                <div className='d-flex justify-content-center mt-3'>
                    {
                        !isEditing ?
                            categoriesProduct.length > 0 ?
                                <i className="bi bi-pencil-fill text-light myCursor" onClick={() => setIsEditing(true)}> edit preferences</i>
                                : <i className="bi bi-plus-lg text-info myCursor" onClick={() => setIsEditing(true)}> add categories</i>
                            : <Button className='px-4 rounded-5' variant="primary" disabled={categoriesProduct || isLoading ? false : true} onClick={() => { handleUpdateAccount() }}>{isLoading ? <Spinner className='me-2' animation="border" size="sm" /> : <i className="bi bi-check2-square me-2"></i>}done</Button>
                    }
                </div>

            </Form.Group>

        </div>
    )
}

export default CategoriesPreferences