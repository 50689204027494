import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUserFunc } from '../states/loginState';
import { getAllAnnouncementsByIdOwnerFunc, setSingleAnnouncement } from '../states/storeState';
import profilePic from '../assets/Graphics/profile.png';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const _Feed = () => {

    const dispatch = useDispatch();

    const token = localStorage.getItem("token");
    const feedIdLS = localStorage.getItem("feedId");
    const feedId = useSelector((state) => state.general.feedId);
    const userData = useSelector((state) => state.login.userData);
    const loading = useSelector((state) => state.login.loading);
    const allData = useSelector((state) => state.myStore.allData);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const [imgWidth, setImgWidth] = useState(((window.innerWidth / 3) - 2) < 333.33 ? ((window.innerWidth / 3) - 2) : 331.33);

    const scrollRefFeed = useRef();

    useEffect(() => {

        if (feedId) {
            dispatch(getSingleUserFunc({ id: feedId, token }));
            dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: feedId, token }))
            localStorage.setItem("feedId", feedId)
        } else if (feedIdLS) {
            dispatch(getSingleUserFunc({ id: feedIdLS, token }));
            dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: feedIdLS, token }))
        }

    }, [feedId]);

    useEffect(() => {
        scrollRefFeed.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);


    return (
        <div className='text-light d-flex justify-content-center pb-5'>
            {
                isLoading || loading ?
                    <Spinner className='mt-5' animation='border' variant='secondary' />
                    :
                    <div className='w-100 myMaxW1000 pb-5' ref={scrollRefFeed}>
                        {
                            userData[0] ?
                                <div className=' p-2'>
                                    <div className='d-flex justify-content-start align-items-center gap-3'>
                                        <div className={`myBgImgCover2 imgChatMain myCursor rounded-5 ms-1`}
                                            /*  style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${userData[0].pics.split(",")[0]})` }} */
                                            style={{ backgroundImage: `url(${profilePic})` }}
                                        ></div>
                                        <div>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div>{userData[0].companyName}</div>

                                                {userData[0].dealer ? <div className='fw-bold myAquaColor'>D</div> : <div className='fw-bold myYellowColor'>M</div>}
                                            </div>
                                            <p className='myLightGrayColor m-0'>{userData[0].city} - {userData[0].country}</p>
                                            <div>
                                                {
                                                    userData[0].dealer ?
                                                        <p className='m-0'>Area: {userData[0].distributionArea}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        <div className='d-flex flex-wrap justify-content-start'>
                            {
                                allData && allData.map((el, index) => {
                                    return (
                                        <Link to={'/announcement'} key={`feed1-${index}`}>
                                            <div className={`myBgImgCover2 myCursor`}
                                                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${el && el.pics.split(",")[0]})`, width: `${imgWidth}px`, height: `${imgWidth}px`, margin: "1px" }}
                                                onClick={() => { dispatch(setSingleAnnouncement(el)) }}
                                            ></div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

export default _Feed