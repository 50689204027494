import React from 'react';
import profilePic from '../assets/Graphics/profile.png';
import { useDispatch } from 'react-redux';
import { setFeedId } from '../states/generalState';
import { Link } from 'react-router-dom';

const UserConnectionCard = ({ singleData }) => {

    const dispatch = useDispatch();
    

    return (
        <Link className='noLinkStyle' to={'/feed'} onClick={() => { localStorage.removeItem("feedId"); dispatch(setFeedId(singleData.id)) }}>
            <div className='text-light m-1 d-flex p-1 rounded-5 gap-2 align-items-center justify-content-between myCursor' style={{ borderBottom: "1px solid #555" }} >

                <div className='d-flex gap-2 align-items-center'>
                    <div className={`myBgImgCover2 imgUserConnections myCursor rounded-5 ms-1`}
                        style={{ backgroundImage: `url(${profilePic})` }}
                    ></div>
                    <h6>{singleData.companyName}</h6>
                </div>
                <div className='me-3'>
                    {
                        singleData.manufacturer ?
                            <h5 className='myYellowColor'>M</h5>
                            : <h5 className='myAquaColor'>D</h5>
                    }
                </div>

            </div>
        </Link>
    )
}

export default UserConnectionCard