import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import "../styles/accountCardBody.css";
import notificationAssigner from '../functions/notificationAssigner';
import { useDispatch, useSelector } from 'react-redux';
import { goToMyChat } from '../states/chatState';
import { useNavigate } from 'react-router';

const CardPenRejAnnouncementLine = ({ idOwn, singleData }) => {

    const [notify, setNotify] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //chat
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const notifyCount = useSelector((state) => state.chat.notifyCount);

    useEffect(() => {
        if (notificationAssigner({ allChatsNotify, idOwn, singleData })) {
            setNotify(true)
        }
    }, [allChatsNotify, notifyCount])

    return (
        <div className='w-100 d-flex justify-content-center' key={Math.random()}>

            <div className="d-flex w-100 m-1 lineCard myMaxW1200 myCursor myBgDark text-light" onClick={() => { dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })); navigate('/chat') }}>

                <div className='myBgImgCover2' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]})` }}></div>

                <div className='px-3 py-2 mainInfoLineCard  w-100 pe-'>
                    <div className='line-clampBeta'>
                        <h5 className='me-3'> {singleData.modelName}</h5>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-1 gap-2'>
                        <p className='m-0'> <span className='fw-bold'>{singleData.price}{singleData.currency ? singleData.currency.split(":")[1] : null}</span> · <span className='myYellowColor'>{singleData.quantity}</span> </p>
                        <p className='m-0 fw-bold line-clampAnn'>{singleData.brandName}</p>
                        <div className='d-flex justify-content-center gap-2'>
                            <h6 className='m-0'><i className="bi bi-eye-fill"></i> {singleData.views}</h6>
                            <h6 className='m-0 myFucsiaRed'><i className="bi bi-suit-heart-fill" ></i> {singleData.posClick}</h6>
                            <h6 className='m-0'>
                                <i className={`bi bi-chat-dots-fill ${notify ? "myChatColor" : "text-secondary"}`}  ></i>
                            </h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CardPenRejAnnouncementLine