import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleAnnouncementFunc } from '../states/storeState';
import { useNavigate } from 'react-router';
import { getUserOutletFunc } from '../states/outletStore';
import decodeToken from '../functions/decodeToken';
import { goToMyChat } from '../states/chatState';
import { Button, Placeholder } from 'react-bootstrap';
import removeLike from '../functions/removeLike';
import addLike from '../functions/addLike';
import { Link } from 'react-router-dom';
import { setFeedId } from '../states/generalState';

const _Announcement = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const singleData = useSelector((state) => state.myStore.singleData);
    const outletUserData = useSelector((state) => state.outlet.outletUserData);
    const outletData = useSelector((state) => state.myStore.outletData);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const isLoading2 = useSelector((state) => state.outlet.isLoading);

    const [numOfPics, setNumOfPics] = useState(0);
    const [picsIndex, setPicsIndex] = useState(0);
    const [swipeXStartPosition, setSwipeXStartPosition] = useState(0);
    const [iLikeThis, setILikeThis] = useState(false);

    const scrollRefAnnouncement = useRef();

    const token = localStorage.getItem("token");
    const tkn = decodeToken();

    const manageSwipe = (e) => {
        if ((swipeXStartPosition - e.changedTouches[0].clientX) > 150) {
            if (numOfPics && ((picsIndex + 1) < numOfPics)) { setPicsIndex(picsIndex + 1) }
        }
        if ((swipeXStartPosition - e.changedTouches[0].clientX) < -150) {
            if (picsIndex > 0) { setPicsIndex(picsIndex - 1) }
        }
    };

    const iLikeIt = () => {
        if (singleData && outletUserData && outletUserData.length) {
            addLike(singleData, outletUserData, outletData, dispatch, navigate)
        }
    };

    const noLikeAnymore = () => {
        if (singleData && outletUserData && outletUserData.length) {
            removeLike(singleData, outletUserData, outletData, dispatch, navigate)
        }
    };

    useEffect(() => {
        if (singleData && singleData.pics) {
            setNumOfPics(singleData.pics.split(",").length)
        }
        if (singleData) {
            localStorage.setItem("annId", singleData.id)
        } else {
            const annId = localStorage.getItem("annId");
            if (annId) {
                dispatch(getSingleAnnouncementFunc({ id: annId, token }))
            } else {
                navigate('/favourites')
            }
        }

    }, [singleData]);

    useEffect(() => {
        if (outletUserData && outletUserData.length) {
            setILikeThis(false);
            singleData && outletUserData[0].outletLikes && outletUserData[0].outletLikes.split(",").map((el) => {
                if (+el === singleData.id) { setILikeThis(true) }
            })

        } else {
            dispatch(getUserOutletFunc({ idOwner: tkn.id, token }))
        }
    }, [outletUserData, singleData]);

    useEffect(() => {
        scrollRefAnnouncement.current?.scrollIntoView({ behavior: "instant", block: "start" });
    }, []);

    return (
        <div className='text-light pb-5 d-flex justify-content-center'>

            <div className='myMaxW700 w-100 pb-5' ref={scrollRefAnnouncement}>
                {
                    singleData && singleData.pics ?
                        <div className='position-relative' onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageSwipe(e) }} >
                            {
                                numOfPics && numOfPics > 1 ?
                                    <div className='overlayBottomThin d-flex align-items-end justify-content-center'>
                                        {
                                            [...Array(numOfPics)].map((el, index) => {
                                                return <div className={`dotImg ${index === picsIndex ? "bg-dark" : "bg-light"}`} key={`announcement1-${index}`}></div>
                                            })
                                        }
                                    </div>
                                    : null
                            }
                            <img className='w-100' src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[picsIndex]}`} alt="" />
                        </div>
                        : null
                }

                <div className='px-3 py-2 d-flex gap-2 align-items-center justify-content-between'>
                    <div className='d-flex gap-3 align-items-center'>
                        <i className="bi bi-eye-fill"> {singleData && singleData.views}</i>
                        <i className="bi bi-heart-fill myFucsiaRed"> {singleData && singleData.posClick}</i>
                    </div>
                    {
                        singleData && singleData.idOwner === tkn.id ?
                            null
                            : iLikeThis ?
                                <div className='myBgChat px-2 rounded-5 text-dark myCursor' onClick={() => { dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })); navigate('/chat') }}>
                                    write a message <i className="bi bi-chat-dots-fill"></i>
                                </div>
                                : <i className="bi bi-heart myCursor" onClick={iLikeIt}> </i>
                    }
                </div>

                {
                    singleData ?
                        <div className='p-2 d-flex flex-column gap-3'>
                            {
                                singleData.user ?
                                    <Link className="myYellowColor noDecoration fw-bold" to={'/feed'} onClick={() => { localStorage.removeItem("feedId"); dispatch(setFeedId(singleData.idOwner)) }}><p className='m-0 line-clamp3 '><i className="bi bi-person-circle"></i> {singleData.user.companyName}</p></Link>
                                    : null
                            }
                            <h5>{singleData.modelName}</h5>

                            {
                                !singleData || isLoading ?
                                    <Placeholder animation="glow"><Placeholder xs={6} /></Placeholder> :
                                    <div className='d-flex align-items-center flex-column gap-3 justify-content-between py-4'>
                                        <div className='d-flex flex-wrap gap-3 align-items-center'>
                                            <div className='d-flex align-items-top myYellowColor'>
                                                <h4 className={`${singleData.price > 0 ? "" : "myBlurryPrice"}`}>{singleData.price > 0 ? singleData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "000"}</h4>
                                                <p >,00{singleData.currency ? singleData.currency.split(":")[1] : null}</p>
                                            </div>
                                            <div>
                                                <i className="bi bi-box-seam-fill"> {singleData.quantity} items</i>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center px-3 py-1 rounded-5 myBgDark text-light'>
                                            <h4 className={`${singleData.price > 0 ? "" : "myBlurryPrice"} fw-light`}>{singleData.price > 0 ? (Math.floor((singleData.price) / (singleData.quantity))).toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "00"}</h4>
                                            <p className='fw-light'>,{singleData.price > 0 ? (Math.round((singleData.price) / (singleData.quantity) * 100) / 100).toString().split(".")[1] : null}</p>
                                            <h6 className='fw-light'>{singleData.currency ? singleData.currency.split(":")[1] : null}/item</h6>
                                        </div>
                                    </div>
                            }

                            <h5 className='fw-light'>{singleData.description}</h5>

                            <div>
                                <hr />
                                <h5 className='fw-normal mt-4'> Technical details</h5>
                                <div>
                                    <ul className='mt-3'>
                                        {
                                            singleData.techDetail && singleData.techDetail.split(",").map((el, index) => {
                                                return <li key={`cardannouncement2-${index}`}>{el.split("£")[1]}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : null
                }

                {
                    iLikeThis ?
                        <div className='d-flex justify-content-center mt-5 mb-3'>
                            <Button className='rounded-5 px-3' disabled={isLoading || isLoading2 ? true : false} variant='danger' onClick={noLikeAnymore}>Remove Like <i className="bi bi-heartbreak-fill ms-1"></i></Button>
                        </div>
                        : null
                }

            </div>

            <div className='pb-5'>{/* my spacer */}</div>

        </div>
    )
}

export default _Announcement