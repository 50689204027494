import React from 'react';
import _Announcement from '../components/_Announcement';
import MainLayout from '../Layouts/MainLayout';

const _AnnouncementPage = () => {
    return (
        <MainLayout>
            <_Announcement />
        </MainLayout>
    )
}

export default _AnnouncementPage