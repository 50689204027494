import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { postLoginFunc } from '../states/loginState';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { userSearchFunc } from '../states/loginState';
import Logo from '../assets/Graphics/mainLogo.svg';
import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import serverResponseManagement from '../functions/serverResponseManagement';


const _Login = () => {
    const [email, setEmail] = useState('');
    const [pssw, setPssw] = useState('');
    const [firstSendTry, setFirstSendTry] = useState(true);
    const [serverResponse, setServerResponse] = useState("");
    const [resetPssw, setResetPssw] = useState(false);
    const [emailCheckHolding, setCheckEmailHolding] = useState(false);
    const [psswVisible, setPsswVisible] = useState(false);
    const [jailLoading, setJailLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginLoading = useSelector((state) => state.login.loading)

    const isEmailValid = email.includes("@") && email.includes(".") && email.length > 6;
    const formOk = isEmailValid && pssw;

    const handleSubmit = async () => {

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
        const encryptedMail = encrypt.encrypt(email.toLowerCase().trim());
        const encryptedPssw = encrypt.encrypt(pssw.trim());

        let loginPayload = {
            email: encryptedMail,
            pssw: encryptedPssw
        }


        if (formOk) {

            dispatch(postLoginFunc(loginPayload))
                .then((res) => {
                    if (res.payload && res.payload.statusCode === 500) {
                        navigate('/servererror')
                    };
                    if (res.payload && res.payload.statusCode === 429) {
                        setJailLoading(true);
                        setTimeout(() => {
                            setJailLoading(false)
                            serverResponseManagement(res, navigate, dispatch);
                        }, 15000)
                    };
                    if (res.payload && res.payload.statusCode === 200) {
                        localStorage.setItem('token', res.payload.token);
                        setEmail('');
                        setPssw('');
                        navigate('/')
                    } else {
                        setServerResponse(res.payload.message)
                    }
                })
                .catch((err) => {
                    console.log('login or server error', err);
                })
        }


    };

    const manageReset = async () => {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
        const encryptedMail = encrypt.encrypt(email.toLowerCase().trim());

        dispatch(userSearchFunc({ email: encryptedMail }))
            .then((res) => {
                if (res.payload && res.payload.statusCode === 500) {
                    navigate('/servererror')
                };
                if (res.payload && res.payload.statusCode === 200) {
                    setCheckEmailHolding(true)
                } else {
                    setServerResponse(res.payload.message);
                }
            })
    };

    useEffect(() => {
        if (psswVisible) {
            setTimeout(() => {
                setPsswVisible(false)
            }, 5000)
        }
    }, [psswVisible])

    return (
        <div className='d-flex align-items-center justify-content-center' style={{ height: "100vh" }}>
            <div className='text-light w-100 myMaxW600 p-3 pt-0 mx-1'>

                {
                    resetPssw || emailCheckHolding ?
                        null :
                        <div>
                            <div className='mb-4 logoLogin display-4 text-center montserrat-alternates-medium'>myStocker<img className='m-1 ms-3' src={Logo} /></div>

                            <h3 className='text-center myMidGrayColor mb-4'>login</h3>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control placeholder="email" aria-label="email" aria-describedby="basic-addon1"
                                    className='text-lowercase'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    maxLength={160}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                                <Form.Control type={`${psswVisible ? "text" : "password"}`} placeholder="password" aria-label="Password" aria-describedby="basic-addon1"
                                    onChange={(e) => { setPssw(e.target.value); }}
                                    value={pssw}
                                    maxLength={26}
                                />
                                <div className=' rounded-end-5 d-flex justify-content-center align-items-center myBgDarkGray' onClick={() => setPsswVisible(!psswVisible)}>
                                    <i className={`bi bi-eye${psswVisible ? "" : "-slash"}-fill px-3 myCursor`}></i>
                                </div>
                            </InputGroup>
                            <div className='d-flex align-items-center justify-content-center pt-3'>
                                <Button className='w-100 fw-bold' variant='warning' disabled={formOk && !jailLoading ? false : true} onClick={() => { handleSubmit(); setFirstSendTry(false) }}>{loginLoading || jailLoading ? <Spinner animation="border" size='sm' /> : "login"}</Button>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='mt-4'>
                                    <Link className='link-warning' to={'/signin'}><h6 className='myCursor fw-bold m-0' onClick={() => setResetPssw(true)}>Create Account</h6></Link>
                                </div>
                                <div className='mt-4'>
                                    <a className='myCursor myLightGrayColor' onClick={() => setResetPssw(true)}>forgot password?</a>
                                </div>
                            </div>
                            <p className="mt-3 text-center">{firstSendTry || formOk ? "" : <i className="bi bi-exclamation-triangle-fill text-danger"> Fill the form correctly</i>}</p>
                            {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
                        </div>
                }

                {
                    resetPssw && !emailCheckHolding ?
                        <div>
                            <h3 className='text-center myMidGrayColor mb-4'>Forgot Password?</h3>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control placeholder="email" aria-label="email" aria-describedby="basic-addon1"
                                    className='text-lowercase'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    maxLength={160}
                                />
                            </InputGroup>
                            <div className='d-flex align-items-center justify-content-center pt-2'>
                                <Button className='w-100 fw-bold' variant="warning" disabled={isEmailValid ? false : true} onClick={() => { manageReset() }}>{loginLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
                            </div>
                            <div className='mt-4 text-end'>
                                <a className='myCursor myLightGrayColor' onClick={() => setResetPssw(false)}>login</a>
                            </div>
                            {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
                        </div>
                        : null
                }

                {
                    emailCheckHolding ?
                        <div className='pt-2'>
                            <div className='d-flex align-items-center justify-content-center gap-3'>
                                <Spinner animation="grow" size='sm' />
                                <span>Check your email. We sent a link to set a new password.</span>
                            </div>
                            <p className='mt-3 text-center myLightGrayColor'>* If you haven't received any email, repeat the process from the beginning, making sure to insert correctly the same email with which the account has been created.</p>
                            <div className='text-center'><a className='myCursor link-warning' onClick={() => window.location.reload()}>login</a></div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default _Login