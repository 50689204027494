import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from 'react-redux';



const StripeSubscribe = ({ product }) => {

    const [clientSecret, setClientSecret] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const dcddMail = useSelector((state) => state.general.dcdTkn.email);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://example.com/order/123/complete",
            }
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    const stripeCheckout = async () => {
        try {
            await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/subscribewithstripe`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    priceId: null,
                    id: product.id,
                    type: product.type,
                    planIdStripe: product.planIdStripe,
                    price: product.price,
                    quantity: product.quantity,
                    currency: product.currency.toLowerCase(),
                    userEmail: dcddMail
                })
            })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));
        } catch (error) {
            console.log(error);
        }
    };


    const handleSubscription = async () => {
        const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        const payload = {
            priceId: null,
            id: product.id,
            type: product.type,
            quantity: product.quantity,
            planIdStripe: product.planIdStripe,
            price: product.price,
            currency: product.currency.toLowerCase(),
            userEmail: dcddMail
        }
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_ADDRESS}/subscribewithstripe`,
            {
                method: "POST",
                headers: { "Content-Type": "Application/JSON" },
                body: JSON.stringify(payload),
            }
        );

        if (response.status === 409) {
            const data = await response.json();
            if (data && data.redirectUrl) {
                window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
            }
        } else {
            const session = await response.json();
            stripePromise.redirectToCheckout({
                sessionId: session.id,
            });
        }
    };

    return (

        <div className="mt-5 d-flex justify-content-center">
            <div className='myMaxW700 w-100 p-4 pb-1'>
                <Button className='w-100 p-3 py-2' variant={"dark"} onClick={() => handleSubscription()}> <h5 className='fw-light my-1'><i className="bi bi-credit-card-fill me-2"></i>Credit Card</h5> </Button>
            </div>
        </div>
    )
}

export default StripeSubscribe