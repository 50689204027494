import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import MainLayout from '../Layouts/MainLayout';

const RedirectPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/')
    }, [])

    return (
        <MainLayout></MainLayout>
    )
}

export default RedirectPage