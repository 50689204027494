import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAnnouncementsFunc } from '../states/storeState';
import CardPendingAnnouncement from './CardPendingAnnouncement';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router';
import serverResponseManagement from '../functions/serverResponseManagement';

const _PendingAnnouncements = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allDataMediation = useSelector((state) => state.myStore.allDataMediation);
    const isLoading = useSelector((state) => state.myStore.isLoading);

    useEffect(() => {
        dispatch(getAllAnnouncementsFunc({ token: localStorage.getItem("token"), status: 0 }))
            .then((res) => {
                serverResponseManagement(res, navigate, dispatch);
            })
    }, [])


    return (
        <div className='py-4 text-light px-3'>
            <h1 className='text-center fw-light'>Pending Announcements</h1>
            <div className='d-flex flex-wrap justify-content-center mt-3'>
                {
                    isLoading ?
                        <div className='text-center my-4'><Spinner animation="border" variant="secondary" /></div>
                        :
                        allDataMediation && allDataMediation.map((el, index) => {
                            return <div key={`pendingannouncements1-${index}`}><CardPendingAnnouncement singleData={[el]} isLoading={isLoading} /></div>
                        })
                }
            </div>
            <div className='pb-5'></div>
        </div>


    )
}

export default _PendingAnnouncements