import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

const TooManyRequestsPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 10000)
    }, [])

    return (
        <div className='text-center pt-5 text-light myBgAbsBlack px-3' style={{ height: "100vh" }}>
            <h1>Too many requests!</h1>
            <h3 className='mt-3 fw-light'>Avoid to reload pages multiple times quickly.</h3>

            <h4 className='mt-5 fw-light'>You will redirect to Home in...</h4>
            <div className='p-4 pb-5 d-flex flex-column align-items-center'>
                <div className='myBgDarkGray rounded-5 mt-0 mx-3 myMaxW700 w-100'>
                    <div className={"myBgYellow rounded percentageBar3"} style={{ height: "8px" }}></div>
                </div>
            </div>
        </div>
    )
}

export default TooManyRequestsPage