import { React, useEffect, useRef, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import CardPenRejAnnouncementReducedForm from './CardPenRejAnnouncementReducedForm';
import { setIsPenRejModalEditing } from '../states/generalState';
import productCategories from '../assets/JSON/productCategories.json';
import "../styles/accountCardBody.css";
import { generateScore } from '../states/annScoreState';
import { goToMyChat, clearUsersById } from '../states/chatState';
import notificationAssigner from '../functions/notificationAssigner';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router';
import { updateAnnouncementFunc } from '../states/storeState';
import serverResponseManagement from '../functions/serverResponseManagement';


const CardPenRejAnnouncementReduced = ({ idOwn, singleData, isLoading, newLikes }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = useRef();

    const [minimize, setMinimize] = useState(true);
    const [imgSelectionCounter, setImgSelectionCounter] = useState(0);
    const [notify, setNotify] = useState(false);
    const [score, setScore] = useState(0);
    const [disableNotify, setDisableNotify] = useState(false);

    //loading states
    const isDeletingPics = useSelector((state) => state.uploadFile.isDeletingPics);
    const isUpdating = useSelector((state) => state.myStore.isLoading);
    //open and keep edit modal open state. 
    const isPenRejModalEditing = useSelector((state) => state.general.isPenRejModalEditing);
    //announcement score
    const finalScore = useSelector((state) => state.annScore.finalScore);
    //chat
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const notifyCount = useSelector((state) => state.chat.notifyCount);

    const token = localStorage.getItem("token");

    const archiveAnnouncement = () => {
        if (window.confirm("Do you want to archive this product? You will find it later on Archived Product section.")) {
            dispatch(updateAnnouncementFunc({ payload: { id: singleData.id, status: 2 }, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) { window.location.reload() }
                })
        }
    }
    const unArchiveAnnouncement = () => {
        if (window.confirm("Do you want to publish this product again? It will not require a new approval from our mediation system.")) {
            dispatch(updateAnnouncementFunc({ payload: { id: singleData.id, status: 1 }, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) { window.location.reload() }
                })
        }
    }

    useEffect(() => {
        if (notificationAssigner({ allChatsNotify, idOwn, singleData })) {
            setNotify(true)
        }
    }, [allChatsNotify, notifyCount])

    useEffect(() => {
        dispatch(generateScore(singleData))
    }, [])

    useEffect(() => {
        finalScore && finalScore.map((el) => { if (singleData.id === el.id) { setScore(el.score) } })
    }, [finalScore])

    useEffect(() => {
        if (isPenRejModalEditing.value) {
            formRef.current?.scrollIntoView({ behavior: "instant", block: "start" });
        }
    }, [isPenRejModalEditing.value])

    return (
        <div key={nanoid()}>

            {
                isPenRejModalEditing.value && isPenRejModalEditing.id === singleData.id || localStorage.getItem("editId") == singleData.id ?

                    <div className='d-flex justify-content-center '>

                        <div className='mt-5 myZindex4 d-flex justify-content-center' >
                            {
                                isDeletingPics || isUpdating ?
                                    <div className=' myZindex5 position-absolute start-0 w-100 h-100 top-0 myBgTransparentHigh border d-flex align-items-center justify-content-center '>
                                        {/* My Loading Overlay */}
                                        {isLoading ? <Spinner animation="grow" /> : null}
                                    </div>
                                    : null
                            }

                            <div ref={formRef} className='p-5 px-2 myBgDark222 position-absolute text-center myOverflowY text-light top-0' style={{ height: "calc(100vh - 65px)", width: "100%", left: "0px" }}>
                                <CardPenRejAnnouncementReducedForm singleData={singleData} />
                            </div>

                        </div>
                    </div>

                    : <div className={`m-1 border border-dark myBgDark text-light myMaxW632 position-relative`} onClick={() => setDisableNotify(true)}>

                        {
                            newLikes && !disableNotify ?
                                <div className={`position-absolute w-100 h-100 myCursor gap-5 display-2 d-flex align-items-center justify-content-center ${notify ? "myBgLikeAndChatNotify text-light" : "end myBgLikeNotify"} `} style={{ zIndex: "3" }} >
                                    <i className="bi bi-heart-fill"> {singleData.newLikes.split(",").length}</i>
                                    {notify && <i className="bi bi-chat-dots-fill"></i>}
                                </div>
                                : notify && !disableNotify ?
                                    <div className={`position-absolute w-100 h-100 myCursor d-flex align-items-center justify-content-center gap-4 display-2 ${notify ? "myBgChatNotify text-light" : "myBgLikeNotify"}`} style={{ zIndex: "3" }} >
                                        <i className="bi bi-chat-dots-fill"></i>
                                    </div>
                                    : null
                        }
                        <div className='position-absolute w-100 h-100 myCursor' style={{ zIndex: "1" }} onClick={() => setMinimize(!minimize)}></div>

                        <div style={{ borderLeft: `3px solid ${singleData.status === 3 ? "red" : singleData.status === 0 ? "lightgray" : singleData.status === 2 ? "#f0bd35" : "#90d800"}` }}>

                            <div className={`${minimize ? "myFade" : "position-relative"}`}>

                                <div className='accountCardBody d-flex gap-3'>
                                    <div className='d-flex align-items-center myBgDark ps-1' >
                                        {
                                            !singleData || isLoading ?
                                                <Placeholder className="h-100" animation="glow"><Placeholder xs={12} className="h-100" /><div className='imgGalleryMain'></div></Placeholder>
                                                :
                                                <div className={`${minimize ? "myBgImgCover2" : "myBgImgContain"} imgGalleryMain myCursor`}
                                                    style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[imgSelectionCounter]})` }}
                                                ></div>

                                        }
                                    </div>

                                    <div className='mainInfo pt-1' >

                                        {
                                            !singleData || isLoading ?
                                                <Placeholder animation="glow"><Placeholder xs={11} /></Placeholder>
                                                : <div className={`${minimize ? "line-clamp1" : ""} py-2 pe-5`}><h4 className='fw-light'>{singleData.modelName}</h4></div>
                                        }
                                        {
                                            !singleData || isLoading ?
                                                <Placeholder animation="glow"><Placeholder xs={11} /></Placeholder> :
                                                <div className='d-flex align-items-center gap-3 w-100'>
                                                    {
                                                        singleData.quantity > 0 ?
                                                            <div className='d-flex w-25'>
                                                                <h6 className={`noBreak ${minimize ? "line-clamp3" : ""}`}>{singleData.quantity} items</h6>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className='d-flex align-items-center brandName w-75'>
                                                        <p className={`m-0 fw-normal myLightGrayColor ${minimize ? "line-clamp3" : ""}`}>{singleData.brandName}</p>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            !singleData || isLoading ?
                                                <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                                <div className={`d-flex position-relative w-100 align-items-center py-1 ${minimize ? "noTextBreak line-clamp1 gap-2" : "flex-wrap"}`} >
                                                    <div className='position-absolute w-100 h-100 clampDarkFading'></div>
                                                    <div className='d-flex align-items-top '>
                                                        <h3 className={`${singleData.price > 0 ? "" : "myBlurryPrice ps-1"} fw-normal`}>{singleData.price > 0 ? singleData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "000"}</h3>
                                                        <p className='fw-light m-0'>00{singleData.currency ? singleData.currency.split(":")[1] : null}</p>
                                                    </div>
                                                    <div className='d-flex align-items-top'>
                                                        <h4 className={`${singleData.price > 0 ? "" : "myBlurryPrice ps-1"} fw-normal`}>{singleData.price > 0 ? (Math.floor((singleData.price) / (singleData.quantity))).toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "00"}</h4>
                                                        <p className='fw-light m-0 me-1'>{singleData.price > 0 ? (Math.round((singleData.price) / (singleData.quantity) * 100) / 100).toString().split(".")[1] : null}</p>
                                                        <p className='fw-light m-0'>{singleData.currency ? singleData.currency.split(":")[1] : null}/item</p>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            !singleData || isLoading ?
                                                <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                                <div className='w-100 d-flex align-items-center mb-2'>
                                                    <div className='myBgDarkGray m-0 w-100 rounded-5 myBgWhite' style={{ height: "5px" }}>
                                                        <div className={`scoreBarLow rounded-5 h-100 ${score > 91 ? "scoreBarLegend" : score > 79 ? "scoreBarHigh" : score > 59 ? "scoreBarMedium" : null}`} style={{ width: `${score}%` }}></div>
                                                    </div>
                                                    <h6 className='ms-2 noBreak'>{score / 10}</h6>
                                                </div>
                                        }
                                        {
                                            !singleData || isLoading ?
                                                null :
                                                <div className='mb-2 d-flex flex-wrap justify-content-end gap-3 align-items-center'>
                                                    <h6 className='m-0'><i className="bi bi-eye-fill"></i> {singleData.views}</h6>
                                                    <h6 className='m-0 myFucsiaRed'><i className="bi bi-suit-heart-fill" ></i> {singleData.posClick}</h6>
                                                    <h6 className='m-0 myZindex2'>
                                                        <i className={`bi bi-chat-dots-fill ${notify ? "myChatColor" : "text-secondary"} myCursor`} onClick={() => { dispatch(clearUsersById()); dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: false, openChat: true })); navigate('/chat') }}></i>
                                                    </h6>
                                                </div>
                                        }
                                    </div>

                                    {
                                        singleData.status === 1 ?
                                            <div className='position-relative'>
                                                <div className='position-absolute editPencil rounded-5 myCursor' onClick={archiveAnnouncement}>
                                                    <i className="bi bi-trash-fill myLightGrayColor"></i>
                                                </div>
                                            </div>
                                            :
                                            singleData.status === 2 ?
                                                <div className='position-relative'>
                                                    <div className='position-absolute editPencil rounded-5 myCursor' onClick={unArchiveAnnouncement}>
                                                        <i className="bi bi bi-send-fill myLightGrayColor"></i>
                                                    </div>
                                                </div>
                                                :
                                                <div className='position-relative'>
                                                    <div className='position-absolute editPencil rounded-5 myCursor' onClick={() => { document.body.style.overflow = 'hidden'; dispatch(setIsPenRejModalEditing({ value: true, id: singleData.id })) }}>
                                                        <i className="bi bi-pencil-fill myLightGrayColor"></i>
                                                    </div>
                                                </div>

                                    }

                                </div>



                                <div className='w-100'>
                                    {
                                        minimize ?
                                            null
                                            :
                                            <div className='mt-1 ps-1 d-flex align-items-center flex-wrap' >
                                                {
                                                    singleData.pics.split(",").map((el, index) => {
                                                        return (
                                                            <div className={`myBgImgCover imgGalleryCarousel me-1 mb-1 myCursor ${index === imgSelectionCounter ? "imgGalleryCarouselActive" : ""} myZindex2`}
                                                                onClick={() => setImgSelectionCounter(index)}
                                                                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[index]})` }}
                                                                key={`cardpenrejannouncementreduced1-${index}`}
                                                            ></div>
                                                        )

                                                    })
                                                }
                                            </div>
                                    }
                                </div>

                                <div className='w-100'>
                                    {
                                        !minimize ?
                                            <div className='my-3 ps-1 d-flex flex-wrap align-items-center'>
                                                {
                                                    singleData.category && singleData.category.split(",").map((el, index) => {
                                                        return (
                                                            <div key={`cardpenrejannouncementreduced2-${index}`}>
                                                                {productCategories.map((item, index) => {
                                                                    if (item.id === +el) {
                                                                        return <span className={`p-1 categoryTag px-3 text-dark fw-bold m-1 ms-0 rounded-5 d-flex align-iems-center ${item.area == "dental" ? "myBgAqua" : "myBgRed"}`} key={`cardpenrejannouncementreduced3-${index}`}> {minimize ? item.code : item.eng} </span>
                                                                    }
                                                                })}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                    }
                                </div>


                                {
                                    minimize ?
                                        null :
                                        <div className='mb-4 mt-3 text-start'>
                                            {
                                                !singleData || isLoading ?
                                                    <Placeholder animation="glow"><Placeholder xs={6} /></Placeholder> :
                                                    <div className={`${minimize ? "line-clamp2" : ""} mt-4`}>
                                                        <span className='p-1 px-2'>{singleData.status === 0 ? <i><Spinner animation="grow" size="sm" /> On approval</i> : singleData.status === 1 ? <i className="bi bi-check-circle-fill myChatColor"> Approved</i> : <i className="bi bi-ban text-danger"> Rejected:</i>}</span>
                                                        {singleData.status !== 1 ? <p className='text-danger px-3'>{singleData.rejReasons}</p> : null}
                                                    </div>
                                            }

                                        </div>
                                }



                                {
                                    minimize ?
                                        null :
                                        <div>
                                            {
                                                !singleData || isLoading ?
                                                    <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                                    <div>
                                                        <hr />
                                                        <div className='ps-2'>
                                                            <h5 className='mb-1 fw-normal'>Description</h5>
                                                            <h5 className='fw-light preWrap'>{singleData.description}</h5>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                !singleData || isLoading ?
                                                    <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                                    <div className='my-5'>
                                                        <hr />
                                                        <div className='ps-2'>
                                                            <h5 className='fw-normal'> Technical details</h5>
                                                            <ul className='mt-3'>
                                                                {singleData.techDetail && singleData.techDetail.split(",").map((el, index) => {
                                                                    return <li key={`cardpenrejannouncementreduced4-${index}`}>{el.split("£")[1]}</li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                            }

                                            {
                                                !singleData || isLoading ?
                                                    <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                                    <div className='my-5'>
                                                        <hr />
                                                        <div className='d-flex gap-2 flex-wrap myFontSize12px'>
                                                            <span className='px-3 py-1'>Created at: <i className='fw-bold'>{singleData.dataIns.split("T")[0]}</i></span>
                                                            <span className='px-3 py-1'><i className="bi bi-pencil-fill me-2"></i> <i className='fw-bold'>{singleData.dataMod.split("T")[0]}</i></span>
                                                            {singleData.dataApproved && singleData.dataApproved !== "null" ? <span className='px-3 py-1'><i className="bi bi-check-circle-fill me-2 myChatColor"></i> <i className='fw-bold'>{singleData.dataApproved.split("T")[0]}</i></span> : null}
                                                            {singleData.dataRejected && singleData.dataRejected !== "null" ? <span className='px-3 py-1'><i className="bi bi-ban me-2 text-danger"></i> <i className='fw-bold'>{singleData.dataRejected.split("T")[0]}</i></span> : null}
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                }

                                <div className='position-relative'>
                                    <div className='myCursor minimizeArrow' onClick={() => setMinimize(!minimize)}>
                                        <i className={`bi bi-caret-${minimize ? "down" : "up"}-fill text-secondary`}></i>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div >
            }
        </div>

    )
}

export default CardPenRejAnnouncementReduced