import { React, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateAnnouncementFunc } from "../states/storeState";
import { deleteFileFunc } from '../states/uploadFileState';
import { setIsPenRejModalEditing } from '../states/generalState';
import Resizer from "react-image-file-resizer";
import Spinner from 'react-bootstrap/Spinner';
import productCategories from '../assets/JSON/productCategories.json';
import { setCategoriesProduct, delCategoriesProduct, clearCategoriesProduct } from '../states/generalState';
import Dropdown from 'react-bootstrap/Dropdown';
import serverResponseManagement from '../functions/serverResponseManagement';
import { Button } from 'react-bootstrap';
import { setSellingAreaExcluded, delSellingAreaExcluded, clearSellingAreaExcluded, setSellingAreaSelected, delSellingAreaSelected, clearSellingAreaSelected, searchCurrency } from '../states/generalState';
import { useNavigate } from 'react-router';
import countriesArray from '../assets/JSON/countriesIso2Arry.json';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchCountry } from '../states/geonamesState';

const CardPenRejAnnouncementReducedForm = ({ singleData }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [file, setFile] = useState(singleData.pics.split(","));
    const [newFile, setNewFile] = useState("");
    const [slotCode, setSlotCode] = useState(singleData.slotCode);
    const [rejReasons, setRejReasons] = useState(singleData.rejReasons);
    const [brandName, setBrandName] = useState(singleData.brandName);
    const [manufacturerName, setManufacturerName] = useState(singleData.manufacturerName);
    const [modelName, setModelName] = useState(singleData.modelName);
    const [quantity, setQuantity] = useState(singleData.quantity);
    const [price, setPrice] = useState(singleData.price);
    const [currency, setCurrency] = useState(singleData.currency);
    const [productSize, setProductSize] = useState(singleData.productSize);
    const [description, setDescription] = useState(singleData.description);
    const [techDetail, setTechDetail] = useState(singleData.techDetail ? singleData.techDetail.split(",") : []);
    const [techDetailView, setTechDetailView] = useState("");
    const [category, setCategory] = useState(singleData.category.split(","));
    const [expireDate, setExpireDate] = useState(singleData.expireDate);
    const [zoomIn, setZoomIn] = useState({ field: "", value: false });
    const [changeCatgories, setChangeCategories] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [isFirstTry, setIsFirstTry] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [swipeXStartPosition, setSwipeXStartPosition] = useState(0)
    const checkQuantity = (e) => { console.log(price); if (+e.target.value > 0 && quantity === "0") { setQuantity("") } };
    const isCurrencyNeeded = () => { if (price > 0) { return currency } else { return true } };

    const [imgSelectionCounter, setImgSelectionCounter] = useState(0);
    const [newFileItems, setNewFileItems] = useState("");
    const [base64Img, setBase64Img] = useState([]);
    const [base64FakeLoading, setBase64FakeLoading] = useState(false);
    let [selectedIndex, setSelectedIndex] = useState(0);
    const [orderImg, setOrderImg] = useState(false);
    const [reachMaxNumOfPics, setReachMaxNumOfPics] = useState(false);
    const [imgTypeError, setImgTypeError] = useState("");

    const [sellExclCountryDropdownOpen, setSellExclCountryDropdownOpen] = useState(false)
    const [sellWholeWorld, setSellWholeWorld] = useState(!singleData.sellingAreaExcluded && !singleData.sellingAreaSelected ? true : false);
    const [excludeCountry, setExcludeCountry] = useState(!singleData.sellingAreaExcluded && !singleData.sellingAreaSelected ? true : singleData.sellingAreaExcluded ? true : false);
    const [addArea, setAddArea] = useState(false);
    const sellingAreaExcludedISO = useSelector((state) => state.general.sellingAreaExcludedISO);
    const sellingAreaSelectedISO = useSelector((state) => state.general.sellingAreaSelectedISO);
    const countryFiltered = useSelector((state) => state.geonames.countryFiltered);
    const currencyFiltered = useSelector((state) => state.general.currencyFiltered);


    /* selectors */
    const isPenRejModalEditing = useSelector((state) => state.general.isPenRejModalEditing);
    /* loading states */
    const isDeletingPics = useSelector((state) => state.uploadFile.isDeletingPics);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    /* category states */
    const categoriesProduct = useSelector((state) => state.general.categoriesProduct);
    const categoriesProductId = useSelector((state) => state.general.categoriesProductId);
    //errors
    const [techDetailValueError, setTechDetailValueError] = useState(false);
    const conditions = brandName && modelName && quantity !== "" && price !== "" && file && description && category && isCurrencyNeeded() && productSize && !sellExclCountryDropdownOpen;

    const token = localStorage.getItem("token");


    const uploadFile = async (newFile) => {
        const fileData = new FormData();

        [...Array(newFile.length)].map((el, index) => {
            fileData.append('img', newFile[index]);
        })

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/fileupload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: fileData,
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }
    };

    const formCheck = async () => {

        if (conditions) {

            setIsFormFilled(true);
            setIsFirstTry(false);
            setReachMaxNumOfPics(false);
            const uploadedFile = await uploadFile(newFile);
            handleUpdateFormAnnouncement(uploadedFile)

        } else {
            setIsFormFilled(false);
            setIsFirstTry(false);
        }

    };

    const deleteImage = async () => {
        if (window.confirm("Do you want to delete this image? ")) {
            localStorage.setItem("editId", singleData.id);
            dispatch(deleteFileFunc({ fileName: file[imgSelectionCounter], token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode !== 200) {
                        setErrorMessage(res.payload.message)
                    }
                });
            setFile(file.splice(file.indexOf(file[imgSelectionCounter]), 1));
            handleUpdateFormAnnouncement();
            setImgSelectionCounter(0);
            setFile(file)//fondamentale per aggiornare la galleria immagini
        }
    };

    const handleUpdateFormAnnouncement = async (input) => {

        const payload = {
            slotCode: slotCode,
            rejReasons: rejReasons,
            relevance: slotCode,
            brandName: brandName,
            manufacturerName: manufacturerName,
            modelName: modelName,
            quantity: quantity,
            price: price,
            currency: currency,
            pics: newFile ? file.concat(input.img.split(",")).toString() : file.toString(),
            productSize: productSize,
            description: description,
            techDetail: techDetail.toString(),
            category: categoriesProductId.toString(),
            expireDate: expireDate,
            sellingAreaExcluded: sellingAreaExcludedISO.map((el) => { return el.split(":")[1] }),
            sellingAreaSelected: sellWholeWorld ? [] : sellingAreaSelectedISO.map((el) => { return el.split(":")[1] })
        }

        if (singleData.status === 3) {
            dispatch(updateAnnouncementFunc({ payload: { ...payload, id: singleData.id, status: 0, dataRejected: singleData.dataRejected }, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        dispatch(clearCategoriesProduct());
                        window.location.reload()
                    } else {
                        setErrorMessage(res.payload.message)
                    }
                })
        } else {
            dispatch(updateAnnouncementFunc({ payload: { ...payload, id: singleData.id }, token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        window.location.reload()
                    } else {
                        setErrorMessage(res.payload.message)
                    }
                })
        }
    };

    const uploadBase64 = async (e) => {
        let img64Arry = [];
        const newImgsAllowed = 12 - file.length;
        const numOfImgs = e.target.files.length;
        const maxNumOfImgs = numOfImgs > newImgsAllowed ? newImgsAllowed : numOfImgs;

        [...Array(maxNumOfImgs)].map(async (el, index) => {
            const myFile = e.target.files[index];
            if (checkFileType(myFile)) {
                const base64 = await convertBase64(myFile);
                img64Arry.push(base64);
            }
        })
        setBase64FakeLoading(true);
        setTimeout(() => {
            setBase64FakeLoading(false);
            setBase64Img(img64Arry)
        }, 1000)
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    };

    const checkFileType = (file) => {
        const supportedDatatype = ["image/png", "image/jpeg"];        
        if (!supportedDatatype.includes(file.type)) {
            setImgTypeError("File not supported. Add an image in png or jpeg format.");
            return false
        } else {
            return true
        }
    };

    const resizeFile = async (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1300,
                1100,
                "JPEG",
                file.size > 200000 ? file.size > 600000 ? file.size > 5000000 ? 30 : 40 : 70 : 90,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const myResize = async (e) => {
        let imgArray = [];
        const newImgsAllowed = 12 - file.length;
        const numOfImgs = e.target.files.length;
        const maxNumOfImgs = numOfImgs > newImgsAllowed ? newImgsAllowed : numOfImgs;

        [...Array(maxNumOfImgs)].map(async (el, index) => {
            const myFile = e.target.files[index];
            if (checkFileType(myFile)) {
                const image = await resizeFile(myFile);
                imgArray.push(image);
            }
        });
        if (newImgsAllowed) { setNewFile(imgArray) };
        if (numOfImgs > newImgsAllowed) { setReachMaxNumOfPics(true) }
    };

    const moveLeft = () => {
        let newIndex = 0;
        let removeIndex = 0;
        let baseArray = base64Img;
        base64Img.map((el, index) => {
            newIndex = selectedIndex - 1;
            removeIndex = selectedIndex + 1;
            if (newIndex >= 0 && index === selectedIndex) {
                baseArray.splice(newIndex, 0, el);
                baseArray.splice(removeIndex, 1);
                setBase64Img([...baseArray]);
                setBase64Img(baseArray);
                setSelectedIndex(selectedIndex - 1);
            }
        })
    };

    const moveRight = () => {
        let newIndex = 0;
        let removeIndex = 0;
        let baseArray = base64Img;

        base64Img.map((el, index) => {
            newIndex = selectedIndex + 2;
            removeIndex = selectedIndex;
            if (newIndex <= base64Img.length && index === selectedIndex) {
                baseArray.splice(newIndex, 0, el);
                baseArray.splice(removeIndex, 1);
                setBase64Img([...baseArray]);
                setBase64Img(baseArray);
                setSelectedIndex(selectedIndex + 1);
            }
        })
    };

    const moveLeft2 = () => {
        const newArray = [...file];
        if (imgSelectionCounter > 0) {
            newArray.splice(imgSelectionCounter - 1, 1, file[imgSelectionCounter]);
            newArray.splice(imgSelectionCounter, 1, file[imgSelectionCounter - 1]);
            setImgSelectionCounter(imgSelectionCounter - 1)
            setFile(newArray);
        }
    };

    const moveRight2 = () => {
        const newArray = [...file];
        if (imgSelectionCounter < file.length - 1) {
            newArray.splice(imgSelectionCounter + 1, 1, file[imgSelectionCounter]);
            newArray.splice(imgSelectionCounter, 1, file[imgSelectionCounter + 1]);
            setImgSelectionCounter(imgSelectionCounter + 1)
            setFile(newArray);
        }
    };

    const manageCategory = (input) => {
        const { el, e } = input;
        if (categoriesProductId.length < 7 || categoriesProductId.includes(el.id)) {
            if (e.currentTarget.classList.toggle('borderActive')) {
                dispatch(setCategoriesProduct(el));
            } else {
                if (categoriesProductId.length > 1) {
                    dispatch(delCategoriesProduct(el.id))
                } else {
                    e.currentTarget.classList.toggle('borderActive')
                }
            }
        }
    };

    const manageTechDetail = () => {
        console.log(techDetail);

        if (techDetail.length < 20) {
            if (techDetail.length) {
                let newTechDetailArry = [];
                techDetail.map((el, index) => {
                    const splittedElValue = el.split("£")[1];
                    newTechDetailArry.push([index, splittedElValue].join("£"));
                })
                setTechDetail([...newTechDetailArry, `${techDetail.length}£` + techDetailView])
            } else {
                setTechDetail([`${techDetail.length}£` + techDetailView])
            }
        }
    };

    const deleteTechItem = (input) => {
        let newTechDetailArry = [];
        techDetail.map((el) => {
            if (el.split("£")[0] !== input) {
                newTechDetailArry.push(el);
            }
        })
        setTechDetail(newTechDetailArry)
    };

    const updatingImages = () => {
        localStorage.setItem("editId", singleData.id);
        formCheck();
        setIsUpdating(true)
    };

    const manageSwipe = (e) => {
        if ((swipeXStartPosition - e.changedTouches[0].clientX) > 150) {
            if (imgSelectionCounter < file.length - 1) {
                setImgSelectionCounter(imgSelectionCounter + 1)
            }
        }
        if ((swipeXStartPosition - e.changedTouches[0].clientX) < -150) {
            if (imgSelectionCounter > 0) {
                setImgSelectionCounter(imgSelectionCounter - 1)
            }
        }
    };

    useEffect(() => {
        category.map((el) => {
            productCategories.map((item) => {
                if (+el === item.id) {
                    dispatch(setCategoriesProduct(item));
                }
            })
        })
    }, []);

    useEffect(() => {
        if (singleData.sellingAreaExcluded) {
            singleData.sellingAreaExcluded.split(",").map((el) => {
                countriesArray && countriesArray.iso2.map((item) => {
                    if (item.split(":")[1] === el) { dispatch(setSellingAreaExcluded(item)); }
                })
            })
        }
        if (singleData.sellingAreaSelected) {
            singleData.sellingAreaSelected.split(",").map((el) => {
                countriesArray && countriesArray.iso2.map((item) => {
                    if (item.split(":")[1] === el) { dispatch(setSellingAreaSelected(item)); }
                })
            })
        }
    }, []);

    return (
        <div>
            <div className='d-flex justify-content-center mt-5 px-2'>

                <Form className='text-light d-flex flex-wrap justify-content-center w-100' encType='multipart/form-data'>


                    <div className='d-flex flex-column align-items-center gap-5 w-100'>

                        <div className='position-absolute top-0 end-0 display-6 p-4 pt-4 myCursor'>
                            <i className="bi bi-x-lg" onClick={() => { document.body.style.overflow = ''; dispatch(setIsPenRejModalEditing(!isPenRejModalEditing)); localStorage.removeItem("editId"); dispatch(clearCategoriesProduct()) }}></i>
                        </div>

                        <div className='d-flex flex-wrap justify-content-center gap-4 w-100'>

                            <div className='position-relative myMaxW500 '>
                                {
                                    file.length > 1 &&
                                    <div className='position-absolute top-0 end-0 p-3'>
                                        <div className='bg-light border border-secondary p-2 trashButtonImage  text-danger'>
                                            {
                                                isDeletingPics ?
                                                    <Spinner animation="border" variant='danger' />
                                                    : <i className="bi bi-trash-fill myCursor" onClick={() => { deleteImage() }}></i>
                                            }
                                        </div>
                                    </div>
                                }

                                <div>
                                    <div className='myH400 w-100 myBgImgContain' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${file[imgSelectionCounter]})`, maxWidth: "100%" }} onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageSwipe(e) }} />
                                    <div className='w-100 d-flex justify-content-center'>
                                        <div className='mt-3 d-flex align-items-center justify-content-center flex-wrap myMaxW500'>
                                            {
                                                file && file.map((el, index) => {
                                                    return (
                                                        <div className={`myBgImgCover imgGalleryCarousel me-1 mb-1 myCursor ${index === imgSelectionCounter ? "imgGalleryCarouselActive" : ""}`}
                                                            onClick={() => setImgSelectionCounter(index)}
                                                            style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${file[index]})` }}
                                                            key={`cardpenrejannouncementreducedform1-${index}`}
                                                        ></div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    file.length && !newFile ?
                                        <div className='d-flex justify-content-center my-4 py-1'>
                                            {
                                                orderImg ?
                                                    <div className='d-flex justify-content-around myMaxW300 w-100'>
                                                        <i className="bi bi-arrow-left px-2 py-1 rounded-5 myBgDarkGray myCursor" onClick={moveLeft2}></i>
                                                        <Button className='p-0 px-4 rounded-5' disabled={!conditions || isUpdating ? true : false} variant='primary' onClick={updatingImages}>done <i className="bi bi-check2-square"></i></Button>
                                                        <i className="bi bi-arrow-right px-2 py-1 rounded-5 myBgDarkGray myCursor" onClick={moveRight2}></i>
                                                    </div>
                                                    :
                                                    <h6 className='myYellowColor myCursor' onClick={() => setOrderImg(true)}><i className="bi bi-arrow-left-right"></i> change pics order</h6>
                                            }
                                        </div>
                                        : null
                                }

                                <Form.Group className="mb-3 mt-3 d-flex align-items-center justify-content-center">
                                    <input type='file' style={{ display: "none" }} id="upload-input" accept="image/png, image/jpeg" multiple onChange={(e) => { myResize(e); setNewFileItems(e.target.files); uploadBase64(e) }} />
                                    <label className='myCursor myInputFile myBgDarkGray rounded-5 d-flex align-items-center' id="upload-input" htmlFor="upload-input">
                                        <div className='myBgYellow fw-bold text-dark p-1 px-3 rounded-5'>choose a file</div>
                                        <p className='text-light m-0 p1 px-3' htmlFor="upload-input">{!newFile ? "select one or multiple images" : `${JSON.stringify(newFileItems.length)} file selected`}</p>
                                    </label>
                                </Form.Group>

                                {
                                    imgTypeError ?
                                        <h6 className='text-center text-danger mb-3'>{imgTypeError}</h6>
                                        : null
                                }

                                {base64Img.length ? <h4 className='fw-normal mb-2 text-center'>{base64Img.length} new image{base64Img.length > 1 ? "s" : ""}</h4> : null}
                                {
                                    base64FakeLoading ?
                                        <div className='px-3 py-3'>
                                            <div className='myBgDarkGray rounded w-100'>
                                                <div className={"myBgYellow rounded percentageBar4"} style={{ height: "8px" }}></div>
                                            </div>
                                        </div>
                                        :
                                        <div className='d-flex justify-content-center flex-wrap myOverFlowXHidden mb-3'>
                                            {
                                                base64Img && base64Img.map((el, index) => {
                                                    return <div key={`cardpenrejannouncementreducedform2-${index}`} className={`mySquare80 myOverFlowXHidden myOverflowYHidden d-flex align-items-center border myCursor popImgAnim ${selectedIndex === index ? "borderActiveYellow" : ""}`} onClick={() => { setSelectedIndex(index) }} >
                                                        {!index ? <div className='position-absolute myYellowColor d-flex align-items-start justify-content-start mySquare80' style={{ background: "rgb(30,30,30,.5)", transform: "translateX(-2px)" }}><h3 className='ms-1 pt-1 ps-1 fw-bold'>1°</h3></div> : null}
                                                        <img className='w-100' src={el} alt="" />
                                                    </div>
                                                })
                                            }
                                        </div>
                                }


                                {
                                    base64Img.length ?
                                        <div className='d-flex justify-content-center mb-4'>
                                            <div className='d-flex justify-content-around myMaxW300 w-100'>
                                                <i className="bi bi-arrow-left px-2 py-1 rounded-5 myBgDarkGray myCursor" onClick={moveLeft}></i>
                                                {newFile ? isLoading ? <div className='ms-4'><Spinner animation="border" variant='light' /></div> : <Button className='p-0 px-4 rounded-5' disabled={isUpdating || !conditions ? true : false} variant='primary' onClick={updatingImages}>upload <i className="bi bi-cloud-arrow-up-fill"></i></Button> : null}
                                                <i className="bi bi-arrow-right px-2 py-1 rounded-5 myBgDarkGray myCursor" onClick={moveRight}></i>
                                            </div>
                                        </div>
                                        : null
                                }

                                {
                                    reachMaxNumOfPics ? <h6 className='myLightGrayColor px-3'><i className="bi bi-exclamation-triangle-fill"></i> Any product can have a maximum of 12 pictures</h6> : null
                                }

                            </div>


                            <div className='myMaxW500 w-100'>
                                <Form.Group className="mb-4">
                                    <Form.Label>Brand Name</Form.Label>
                                    <Form.Control maxLength={250} type="text" className={`form-control ${brandName ? "" : "highlightError"}`} id="brandName" value={brandName} onChange={(e) => setBrandName(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Model Name</Form.Label>
                                    <Form.Control maxLength={250} type="text" className={`form-control ${modelName ? "" : "highlightError"}`} id="modelName" value={modelName} onChange={(e) => setModelName(e.target.value)} />
                                </Form.Group>

                                <div className='mb-4'>
                                    <Form.Group>
                                        <Form.Label><i className="bi bi-currency-exchange"></i> Price</Form.Label>
                                        <div className='d-flex justify-content-start ms-3 mb-1'>
                                            <Form.Check checked={price === "0" || price === 0 ? true : false} type="switch" id="custom-switch" label="Don't show the Price" onChange={() => { setPrice(price === "0" || price === 0 ? "" : "0") }} />
                                        </div>
                                        {
                                            price === "0" || price === 0 ?
                                                null :
                                                <Form.Control type="number" placeholder="Price" className={`form-control ${price !== "" ? "" : "highlightError"}`} id="price" value={price} onChange={(e) => { if (e.target.value.length < 10) { setPrice(e.target.value); checkQuantity(e) } }} />
                                        }
                                    </Form.Group>

                                    <Dropdown size="sm">
                                        <Dropdown.Toggle variant="warning" id="dropdown-basic" className='mt-2 mx-3' >
                                            <span className='myFontSize14px text-wrap fw-bold' style={{ overflowX: "hidden" }}>{currency ? `${currency.split(":")[1]} (${currency.split(":")[0]})` : "Choose Currency"}</span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item className='fw-bold text-light bg-danger rounded-5 text-center mb-3' onClick={() => setCurrency("")}>Clear Currency <i className="bi bi-trash3-fill"></i></Dropdown.Item>
                                            <input type="text" placeholder="search currency" className="mb-3 currencySearchInput w-100 mx-3" id="serachCurrency" onChange={(e) => dispatch(searchCurrency(e.target.value))} />
                                            {
                                                currencyFiltered && currencyFiltered.map((el, index) => {
                                                    return <Dropdown.Item className='text-wrap' key={`cardpenrejannouncementreducedform3-${index}`} onClick={() => setCurrency(`${el.name}:${el.symbol}:${el.code}`)}>{el.name} - {el.symbol}</Dropdown.Item>
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <Form.Group className="mb-4 ">
                                    <Form.Label><i className="bi bi-box-seam-fill"></i> Quantity</Form.Label>
                                    <div className='d-flex justify-content-start ms-3 mb-1'>
                                        <Form.Check checked={price > 0 ? false : quantity === "0" || quantity === 0 ? true : false} type="switch" id="custom-switch" label="Don't show the Quantity" onChange={() => { if (!+price) { setQuantity(quantity === "0" || quantity === 0 ? "" : "0") } }} />
                                    </div>
                                    {
                                        quantity === "0" || quantity === 0 && +price === 0 ?
                                            null :
                                            <Form.Control type="number" placeholder="Quantity" className={`form-control ${quantity !== "" ? "" : "highlightError"}`} id="quantity" value={quantity} onChange={(e) => { if (e.target.value === "0" && +price > 0) { return } else { if (e.target.value.length < 10) { setQuantity(e.target.value) } } }} />
                                    }
                                </Form.Group>

                                <div className='text-center mt-3 mb-4 myLightGrayColor'><span className=' display-6 py-2'>{price && quantity && quantity != 0 ? (Math.round(price * 1000 / quantity) / 1000) : 0} {currency.split(":")[1]} /item</span></div>

                                <Form.Group className='mb-4'>
                                    <Form.Label><i className="bi bi-rulers"></i> Product Size</Form.Label>
                                    <div className='d-flex justify-content-start ms-3 mb-1'>
                                        <Form.Check defaultChecked={productSize === "Spaceless" ? "checked" : ""} type="switch" id="custom-switch" label="The product is spaceless" onChange={() => { setProductSize(productSize === "Spaceless" ? "" : "Spaceless") }} />
                                    </div>
                                    <Form.Control maxLength={250} disabled={productSize === "Spaceless" ? true : false} type='text' placeholder='Product size' className={`form-control ${productSize ? "" : "highlightError"}`} id="productSize" value={productSize} onChange={(e) => setProductSize(e.target.value)} />
                                </Form.Group>
                            </div>

                        </div>





                        <div className='w-100 myMaxW1000'>
                            <hr className='myDarkGrayColor mb-5' />

                            <div className='px-3 pb-3'>{/* MANUFACTURERS */}
                                <h5 className='mb-2 fw-bold'><i className="bi bi-globe-americas"></i> Geographic visibility </h5>
                                <div className='d-flex justify-content-center'>
                                    <div className='d-flex text-center myBgDarkGray rounded-5 px-1 py-1 mt-2'>
                                        <h6 className={`${excludeCountry ? "myBgYellow text-dark" : ""} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(true) }}>Exclude country</h6>
                                        <h6 className={`${excludeCountry ? "" : "myBgYellow text-dark"} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(false) }}>Select country</h6>
                                    </div>
                                </div>
                                <div className='display-5 my-3'>{excludeCountry ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}</div>
                                <div className='d-flex justify-content-center'>
                                    {
                                        excludeCountry ?
                                            <p>The <b>Exclude country</b> allows you to make your products visible to Dealers located worldwide, <b>except for Dealers located in those countries you want to exclude from the list.</b></p>
                                            :
                                            <p>The <b>Select country</b> allows you to make your products visible to Dealers located <b>only in those countries you select from the list.</b></p>
                                    }
                                </div>
                                {
                                    excludeCountry ?
                                        <>
                                            <div className='d-flex justify-content-center'>
                                                {
                                                    addArea ?
                                                        <div className="my-2 w-100">
                                                            <div className="mb-3 d-flex gap-2 align-items-center">
                                                                <InputGroup >
                                                                    <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                    <Form.Control variant='dark' id="dropdown-basic-button"
                                                                        placeholder='type a country'
                                                                        onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                        onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            {
                                                                sellExclCountryDropdownOpen ?
                                                                    <div className='position-relative'>
                                                                        <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                            {
                                                                                countryFiltered && countryFiltered.map((el, index) => {
                                                                                    return <div className='px-2 rounded-5 myCursor my-1' key={`cardpenrejannouncementreducedform4-${index}`} onClick={() => { dispatch(setSellingAreaExcluded(el)); setAddArea(false); setSellExclCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        :
                                                        <div className='d-flex gap-4 align-items-center flex-wrap my-3 myCursor'>
                                                            <Form.Check defaultChecked={sellWholeWorld ? "checked" : ""} type="switch" id="custom-switch" label="I want to sell worldwide" onChange={() => { setSellWholeWorld(!sellWholeWorld); dispatch(clearSellingAreaExcluded()); dispatch(clearSellingAreaSelected()) }} />
                                                            <div className={`${sellWholeWorld ? "disabledPlus" : null} d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>exclude a country</div>
                                                        </div>
                                                }

                                            </div>
                                            {sellingAreaExcludedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Excluded country/ies</h4> : null}
                                            <div className='text-light d-flex flex-wrap justify-content-center gap-2'>
                                                {
                                                    sellingAreaExcludedISO && sellingAreaExcludedISO.map((el, index) => {
                                                        return (
                                                            <div key={`cardpenrejannouncementreducedform5-${index}`} className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' >
                                                                {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaExcluded(el)) }}></i>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='d-flex justify-content-center'>
                                                {
                                                    addArea ?
                                                        <div className="my-2 w-100">
                                                            <div className="mb-3 d-flex gap-2 align-items-center">
                                                                <InputGroup >
                                                                    <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                    <Form.Control variant='dark' id="dropdown-basic-button"
                                                                        placeholder='type a country'
                                                                        onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                        onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            {
                                                                sellExclCountryDropdownOpen ?
                                                                    <div className='position-relative'>
                                                                        <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                            {
                                                                                countryFiltered && countryFiltered.map((el, index) => {
                                                                                    return <div className='px-2 rounded-5 myCursor my-1' key={`cardpenrejannouncementreducedform6-${index}`} onClick={() => { dispatch(setSellingAreaSelected(el)); setAddArea(false); setSellExclCountryDropdownOpen(false); setSellWholeWorld(false) }}>{el.split(":")[0]}</div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        :
                                                        <div className={`d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>select a country</div>
                                                }

                                            </div>
                                            {sellingAreaSelectedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Selected country/ies</h4> : null}
                                            <div className='text-light d-flex flex-wrap justify-content-center gap-2'>
                                                {
                                                    sellingAreaSelectedISO && sellingAreaSelectedISO.map((el, index) => {
                                                        return (
                                                            <div key={`cardpenrejannouncementreducedform7-${index}`} className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry'>
                                                                {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaSelected(el)) }}></i>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                            <hr className='my-5 myDarkGrayColor' />

                            <Form.Group className="mb-3">
                                <h5 className='mb-2 fw-bold'><i className="bi bi-tags-fill"></i> Categories</h5>
                                {
                                    changeCatgories ?
                                        <div>
                                            <div>
                                                <i className="bi bi-caret-left-fill px-3 py-1 rounded-5 fw-bold myCursor myBgLightGray text-dark" onClick={() => setChangeCategories(false)}> back</i>
                                            </div>
                                            <h5 className='w-100 text-center mb-3 mt-4 fw-light'>Dental Categories</h5>
                                            <div className='d-flex justify-content-center gap-3 mb-3 display-6'>
                                                {
                                                    zoomIn.value && zoomIn.field === "den" ?
                                                        <i className="bi bi-zoom-out myCursor" onClick={() => { setZoomIn({ field: "den", value: false }) }}></i>
                                                        : <i className="bi bi-zoom-in myCursor" onClick={() => { setZoomIn({ field: "den", value: true }) }}></i>
                                                }
                                            </div>
                                            <div className={`${zoomIn.value && zoomIn.field === "den" ? "zoomInClass" : "normalZoom"} d-flex flex-wrap justify-content-center`}>
                                                {
                                                    productCategories.map((el, index) => {
                                                        if (el.area === "dental") {
                                                            return (<span key={`cardpenrejannouncementreducedform8-${index}`}
                                                                className={`m-1 px-2 text-center text-dark rounded-5 myBgAqua myCursor myBorderAcqua ${categoriesProductId.length && categoriesProductId.includes(el.id) && "borderActive"}`}
                                                                onClick={(e) => { manageCategory({ el, e }) }}>{el.eng}</span>)
                                                        }
                                                    })
                                                }
                                            </div>
                                            <h5 className='w-100 text-center mt-5 mb-3 fw-light'>Medical Categories</h5>
                                            <div className='d-flex justify-content-center gap-3 mb-3 display-6'>
                                                {
                                                    zoomIn.value && zoomIn.field === "med" ?
                                                        <i className="bi bi-zoom-out myCursor" onClick={() => { setZoomIn({ field: "med", value: false }) }}></i>
                                                        : <i className="bi bi-zoom-in myCursor" onClick={() => { setZoomIn({ field: "med", value: true }) }}></i>
                                                }
                                            </div>
                                            <div className={`${zoomIn.value && zoomIn.field === "med" ? "zoomInClass" : "normalZoom"} d-flex flex-wrap justify-content-center`}>
                                                {
                                                    productCategories && productCategories.map((el, index) => {
                                                        if (el.area === "medical") {
                                                            return (<span key={`cardpenrejannouncementreducedform9-${index}`}
                                                                className={`px-2 text-dark text-center m-1 rounded-5 myBgRed myCursor myBorderRed ${categoriesProductId.length && categoriesProductId.includes(el.id) && "borderActive"}`}
                                                                onClick={(e) => { manageCategory({ el, e }) }}>{el.eng}</span>)
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div className='mb-4 mt-5'>
                                                <i className="bi bi-check2-square px-4 py-2 rounded-5 myCursor bg-primary" onClick={() => setChangeCategories(false)}> done</i>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <i className="bi bi-pencil-fill rounded-5 myCursor myYellowColor" onClick={() => setChangeCategories(true)}> edit categories</i>
                                        </div>
                                }


                                <div className='d-flex flex-column align-items-center'>
                                    <h6 className='mt-4'>Selected category/ies:</h6>
                                    <div className=' my-3 myMaxW700 d-flex flex-wrap justify-content-center'>
                                        {
                                            categoriesProduct && categoriesProduct.map((el, index) => {
                                                return (
                                                    <div key={`cardpenrejannouncementreducedform10-${index}`} className='normalZoom'>
                                                        {productCategories.map((item, index) => {
                                                            if (item.id === el.id) {
                                                                return <span key={`cardpenrejannouncementreducedform11-${index}`} className={`text-dark p-1 px-3 m-1 rounded-5 d-flex align-iems-center ${item.area == "dental" ? "myBgAqua" : "myBgRed"}`}> {item.eng} </span>
                                                            }
                                                        })}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Form.Group>

                            <hr className='w-100 px-5 my-5 myDarkGrayColor' />

                            <Form.Group className="mb-3">
                                <Form.Label className='ms-3'>Tech Details</Form.Label>
                                {
                                    techDetail.length ?
                                        <ul className='myBgDark py-2 rounded-2'>
                                            {
                                                techDetail.map((el, index) => {
                                                    if (el.length > 0) {
                                                        return <li className='me-3 mt-2 text-light fw-bold' key={`cardpenrejannouncementreducedform12-${index}`} style={{ borderBottom: "1px solid #222" }}>{el.split("£")[1]}<i className="bi bi-x-lg text-danger ms-2 myCursor" onClick={() => { deleteTechItem(el.split("£")[0]) }}></i></li>
                                                    }
                                                })
                                            }
                                        </ul>
                                        : null
                                }
                                <div className='d-flex gap-1 rounded-5 align-items-center pe-1 bg-dark'>
                                    <Form.Control maxLength={250} type="text" placeholder='add a feature and click plus' className="form-control" id="techDetail" value={techDetailView} onChange={(e) => { if (e.nativeEvent.data !== "£" && e.nativeEvent.data !== ",") { setTechDetailView(e.target.value) } else { setTechDetailValueError(true) } }} />
                                    <button className={`rounded-5 ${techDetailView ? "myBgYellow text-dark" : "bg-secondary text-light"}`} disabled={techDetailView ? false : true} onClick={() => { manageTechDetail(); setTechDetailView("") }}><i className="bi bi-plus-lg" ></i></button>
                                </div>
                                {techDetailValueError ? <div className='mt-2 ms-3'>The "£" and "," values are not permitted!</div> : null}
                            </Form.Group>


                            <Form.Group className="mb-3 mt-5">
                                <Form.Label>Description</Form.Label>
                                <Form.Control maxLength={2500} as="textarea" rows={10} type="text" className={`form-control ${description ? "" : "highlightError"} p-3`} id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Form.Group>

                        </div>

                    </div>

                </Form>

            </div>

            <div className='pb-5 mt-4'>
                <div className='d-flex justify-content-center gap-3'>
                    <Button variant="primary" disabled={isUpdating || !conditions ? true : false} onClick={() => { formCheck(); localStorage.removeItem("editId"); setIsUpdating(true) }}>{isUpdating ? <Spinner className='me-1' animation="grow" size='sm' variant='light' /> : <i className="bi bi-arrow-repeat me-1"></i>} Update</Button>
                    <Button variant="secondary" disabled={isUpdating ? true : false}><i className="bi bi-arrow-return-left text-light myCursor " onClick={() => { dispatch(setIsPenRejModalEditing(!isPenRejModalEditing)); localStorage.removeItem("editId"); document.body.style.overflow = ''; }}> Cancel</i></Button>
                </div>
                {
                    errorMessage ?
                        <div className='d-flex align-items-center justify-content-center mt-4 fw-light'><p className='text-light p-3 myMaxW700 bg-danger'>{errorMessage}</p></div>
                        : null
                }
            </div>

        </div>
    )
}

export default CardPenRejAnnouncementReducedForm