import React from 'react';
import MainLayout from '../Layouts/MainLayout';

const RejectionManagmentGuidePage = () => {
  return (
    <MainLayout>
      <div className='d-flex justify-content-center'>
        <div className='text-light p-3 pt-4 pb-5 myMaxW1000 d-flex flex-column gap-4'>
          <h1 className='myLightGrayColor text-secondary pt-4 text-center mb-4'>Rejection Managment Guide</h1>

          <h4 className='text-light fw-light'>
            <i className="bi bi-envelope-at-fill"></i> If your product has been reject, you shold have received an email. Inside the email body you will find a text section with red/orange border, which explain specific reason/s of rejection.
          </h4>

          <h4 className='text-light fw-light'>
            <i className="bi bi-house-fill"></i> Click on the House icon in the bottom bar to visualize your published products.
          </h4>

          <h4 className='text-light fw-light'>
            <i className="bi bi-pencil-fill"></i> Click on the pencil icon on the product card top-right corner to edit the product itself. Inside the edit window there is a red text section explaning the reasons of rejections.
            Modify the product to comply with our policy.
          </h4>

          <h4 className='text-light fw-light'>
            <i className="bi bi-floppy-fill"></i> Save changes by clicking on done button at the borrom of the edit page. By doing that, the product will enter in the approval stage again. Our Team will check it again to make sure the product complies with our policy.
          </h4>

          <h4 className='text-light fw-light'>
            <i className="bi bi-clock-fill"></i> Wait until your products is approved. You will receive a confermation email.
          </h4>

          <div className='py-5'>{/* my spacer */}</div>
        </div>
      </div>
    </MainLayout>
  )
}

export default RejectionManagmentGuidePage