import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import countriesArray from '../assets/JSON/countriesIso2Arry.json';
import phoneCodes from '../assets/JSON/countryPhoneCodes.json';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getCitiesFunc, searchCity, clearSearchCity, searchCountry, resetFilteredCountry } from '../states/geonamesState';
import Spinner from 'react-bootstrap/Spinner';
import { updateAccountFunc } from '../states/signinState';
import { verifyMailFunc, checkEmailDuplicateFunc } from '../states/signinState';
import { Link } from 'react-router-dom';
import { createInvoiceAnagrapicFunc, updateInvoiceAnagrapicFunc, setInvoiceEditingState, setReloadAnagraphic } from '../states/invoiceAnagraphics';
import decodeToken from '../functions/decodeToken';
import { setDistributionArea, delDistributionArea, setSellingAreaExcluded, delSellingAreaExcluded, clearSellingAreaExcluded, setSellingAreaSelected, delSellingAreaSelected, clearSellingAreaSelected } from '../states/generalState';
import JSEncrypt from 'jsencrypt';
import { Button } from 'react-bootstrap';
import serverResponseManagement from '../functions/serverResponseManagement';
import { useNavigate } from 'react-router';


const AccountEditAnagraphic = ({ userData, isInvoiceAnagraphic }) => {
    const [companyName, setCompanyName] = useState(userData.companyName);
    const [email, setEmail] = useState(isInvoiceAnagraphic ? "" : userData.email);
    const [country, setCountry] = useState("");
    const [city, setCity] = useState(userData.city);
    const [state, setState] = useState(userData.state);
    const [phone, setPhone] = useState(isInvoiceAnagraphic ? null : userData.phone.split("-")[1]);
    const [phoneCode, setPhoneCode] = useState(isInvoiceAnagraphic ? null : userData.phone.split("-")[0]);
    const [verifyCode, setVerifyCode] = useState((Math.random() + 1).toString(18).substring(2))
    const [vat, setVat] = useState(isInvoiceAnagraphic && userData.vat ? userData.vat : "");
    const [sdi, setSdi] = useState(isInvoiceAnagraphic && userData.sdi ? userData.sdi : "");
    const [pec, setPec] = useState(isInvoiceAnagraphic && userData.pec ? userData.pec : "");
    const [taxId, setTaxId] = useState(isInvoiceAnagraphic && userData.taxId ? userData.taxId : "");
    const [errorMessage, setErrorMessage] = useState("");
    const [addArea, setAddArea] = useState(false);
    const [sellWholeWorld, setSellWholeWorld] = useState(!userData.sellingAreaExcluded && !userData.sellingAreaSelected ? true : false);
    const [excludeCountry, setExcludeCountry] = useState(!userData.sellingAreaExcluded && !userData.sellingAreaSelected ? true : userData.sellingAreaExcluded ? true : false);
    const [openInfo, setOpenInfo] = useState(false);
    const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
    const [sellExclCountryDropdownOpen, setSellExclCountryDropdownOpen] = useState(false);
    const token = localStorage.getItem("token");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //inputs validation
    const specialCharacter = ["!", "#", "$", "%", "&", "@", "<", ">", "="];
    const isEmailValid = email.includes("@") && email.includes(".") && email.length > 6;
    const [isCityValid, setIsCityValid] = useState(userData.city ? true : false);

    const allCitiesFiltered = useSelector((state) => state.geonames.allCitiesFiltered);
    const countryFiltered = useSelector((state) => state.geonames.countryFiltered);
    const isLoading = useSelector((state) => state.signin.loading);
    const loadingInvoice = useSelector((state) => state.invoiceAnagraphic.isLoading);
    const invoiceAnagraphic = useSelector((state) => state.invoiceAnagraphic.invoiceAnagraphic);
    const distributionAreaISO = useSelector((state) => state.general.distributionAreaISO);
    const sellingAreaExcludedISO = useSelector((state) => state.general.sellingAreaExcludedISO);
    const sellingAreaSelectedISO = useSelector((state) => state.general.sellingAreaSelectedISO);

    const tkn = decodeToken();
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);

    const generateChangePsswLink = () => {
        const myData = { email: userData.email.toLowerCase().trim(), id: userData.id, time: Date.now() };
        const encryptedData = encrypt.encrypt(JSON.stringify(myData));
        return (`/changepssw/${encryptedData.replaceAll("/", "£$£$£$4572h92£")}`)
    };

    const formCheck = async () => {

        const payload = isInvoiceAnagraphic ?
            {
                idOwner: tkn.id,
                companyName: companyName.trim(),
                country: country.split(":")[1],
                city: city.trim(),
                state: state.trim(),
                vat: vat.trim(),
                sdi: sdi.trim(),
                pec: pec.trim(),
                taxId: taxId.trim()
            }
            :
            {
                id: userData.id,
                companyName: companyName.trim(),
                email: email.trim().toLowerCase(),
                distributionArea: distributionAreaISO.map((el) => { return el.split(":")[1] }),
                sellingAreaExcluded: sellingAreaExcludedISO.map((el) => { return el.split(":")[1] }),
                sellingAreaSelected: sellingAreaSelectedISO.map((el) => { return el.split(":")[1] }),
                country: country.split(":")[1],
                unsubscribeTimer: new Date(),
                city: city.trim(),
                state: state.trim(),
                phone: `${phoneCode}-${phone && phone.toString()}`
            }


        if (userData.email !== email.trim() && !isInvoiceAnagraphic) {
            setErrorMessage("");
            dispatch(checkEmailDuplicateFunc({ email: email.trim().toLowerCase(), token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        setErrorMessage(res.payload.message);
                        document.body.style.overflow = ""
                    }
                    if (res.payload && res.payload.statusCode === 201) {

                        dispatch(updateAccountFunc({ payload: { ...payload, verifyCode: verifyCode, accountActive: 0 }, token: localStorage.getItem("token") }))
                            .then((res) => {
                                serverResponseManagement(res, navigate, dispatch);
                                if (res.payload && res.payload.statusCode === 200) {

                                    dispatch(resetFilteredCountry());

                                    const encryptedEmail = encrypt.encrypt(email);
                                    const encryptedCompanyName = encrypt.encrypt(companyName);
                                    const encryptedVerifyCode = encrypt.encrypt(`${userData.id}-${verifyCode}`);

                                    dispatch(verifyMailFunc({ payload: { email: encryptedEmail, companyName: encryptedCompanyName, verifyCode: encryptedVerifyCode }, token: token }))
                                        .then((res) => {
                                            serverResponseManagement(res, navigate, dispatch);
                                            if (res.payload && res.payload.statusCode === 200) {
                                                localStorage.removeItem("verifyMailError")
                                                window.location.reload()
                                            } else {
                                                localStorage.setItem("verifyMailError", res.payload.message);
                                                window.location.reload()

                                            }
                                        })
                                }
                            })
                    }
                })
        } else {

            if (isInvoiceAnagraphic) {

                if (invoiceAnagraphic.length) {
                    dispatch(updateInvoiceAnagrapicFunc({ payload: payload, token: localStorage.getItem("token") }))
                        .then((res) => {
                            serverResponseManagement(res, navigate, dispatch);
                            if (res.payload && res.payload.statusCode === 200) { dispatch(setInvoiceEditingState(false)) }
                        })
                } else {
                    dispatch(createInvoiceAnagrapicFunc({ payload: payload, token: localStorage.getItem("token") }))
                        .then((res) => {
                            serverResponseManagement(res, navigate, dispatch);
                            if (res.payload && res.payload.statusCode === 200) { dispatch(setReloadAnagraphic(true)) }
                        })
                }

            } else {
                dispatch(updateAccountFunc({ payload: payload, token: localStorage.getItem("token") }))
                    .then((res) => {
                        serverResponseManagement(res, navigate, dispatch);
                        if (res.payload && res.payload.statusCode === 200) {
                            window.location.reload()
                        }
                    })
            }
        }
    };

    const getCitiesByCountry = (input) => {
        dispatch(getCitiesFunc({ country: input.split(":")[1], token: token }))
            .then((res) => {
                serverResponseManagement(res, navigate, dispatch);
            });
        dispatch(clearSearchCity());
        setIsCityValid(false);
        setCountry(input);
    };

    useEffect(() => {
        let countryExt = "";
        countriesArray.iso2.map((el) => { if (el.split(":")[1] === userData.country) { getCitiesByCountry(el); return countryExt = el } });
        setCountry(countryExt)
    }, []);

    useEffect(() => {
        country && phoneCodes.map((el) => { if (el.code === country.split(":")[1]) { setPhoneCode(el.dial_code) } });
    }, [country]);

    useEffect(() => {
        if (userData.city) { setIsCityValid(true) } else { setIsCityValid(false) }
    }, [userData])

    useEffect(() => {

        if (userData.sellingAreaExcluded) {
            userData.sellingAreaExcluded.split(",").map((el) => {
                countriesArray && countriesArray.iso2.map((item) => {
                    if (item.split(":")[1] === el) { dispatch(setSellingAreaExcluded(item)); }
                })
            })
        }
        if (userData.sellingAreaSelected) {
            userData.sellingAreaSelected.split(",").map((el) => {
                countriesArray && countriesArray.iso2.map((item) => {
                    if (item.split(":")[1] === el) { dispatch(setSellingAreaSelected(item)); }
                })
            })
        }
        if (userData.distributionArea) {
            userData.distributionArea.split(",").map((el) => {
                countriesArray && countriesArray.iso2.map((item) => {
                    if (item.split(":")[1] === el) { dispatch(setDistributionArea(item)); }
                })
            })
        }
    }, [])


    return (
        <div>

            <div style={{ maxWidth: "800px" }}>
                <div className="mb-3 d-flex gap-2 align-items-center">
                    <InputGroup >
                        <InputGroup.Text id="basic-addon1"><i className="bi bi-buildings-fill"></i></InputGroup.Text>
                        <Form.Control
                            placeholder="Company Name"
                            aria-label="Company Name"
                            aria-describedby="basic-addon1"
                            onChange={(e) => { setCompanyName(e.target.value) }}
                            value={companyName}
                            maxLength={160}
                        />
                    </InputGroup>
                    {companyName ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                </div>

                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                <Form.Control
                                    placeholder="VAT"
                                    aria-label="VAT"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setVat(e.target.value) }}
                                    value={vat}
                                />
                            </InputGroup>
                            {vat ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }

                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                <Form.Control
                                    placeholder="Tax ID"
                                    aria-label="taxId"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setTaxId(e.target.value) }}
                                    value={taxId}
                                />
                            </InputGroup>
                            {taxId ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }

                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                <Form.Control
                                    placeholder="SDI Code"
                                    aria-label="SDI"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setSdi(e.target.value) }}
                                    value={sdi}
                                />
                            </InputGroup>
                            {sdi ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }
                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control
                                    placeholder="pec"
                                    aria-label="pec"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setPec(e.target.value) }}
                                    value={pec}
                                />
                            </InputGroup>
                            {pec ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }

                {
                    isInvoiceAnagraphic ?
                        null :
                        <div>
                            <div className="mb-3 d-flex gap-2 align-items-center">
                                <InputGroup >
                                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                    <Form.Control
                                        className='text-lowercase'
                                        placeholder="email"
                                        aria-label="email"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        value={email}
                                        maxLength={160}
                                    />
                                </InputGroup>
                                {isEmailValid ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                            </div>
                            <div className='d-flex flex-wrap justify-content-center gap-3'>
                                <Link className=' link-light' to={`${generateChangePsswLink()}`}><div className='myCursor'>change password</div></Link>
                            </div>
                        </div>
                }

                {/* -------------------- */}

                <div>
                    {
                        isInvoiceAnagraphic ?
                            <h4 className='text-dark fw-light mb-2 mt-4'>Head Office</h4>
                            : <h5 className='text-light fw-bold mb-3 mt-4'>Company location</h5>
                    }

                    <div>
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                <Form.Control variant='dark' id="dropdown-basic-button"
                                    placeholder='Country'
                                    value={country ? country.split(":")[0] : ""}
                                    onChange={(e) => { setCountry(e.target.value); dispatch(searchCountry(e.target.value)) }}
                                    onClick={() => { setCountryDropdownOpen(true) }}
                                />
                            </InputGroup>
                            {country ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        {
                            countryDropdownOpen ?
                                <div className='position-relative'>
                                    <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                        {
                                            countryFiltered && countryFiltered.map((el, index) => {
                                                return <div className='px-2 rounded-5 myCursor my-1' key={`accounteditanagraphic1-${index}`} onClick={() => { getCitiesByCountry(el); setCity(""); setCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                            })
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </div>

                    <div className="mb-3">
                        <div className='d-flex gap-2 align-items-center'>
                            <InputGroup >
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-geo-fill"></i></InputGroup.Text>
                                <Form.Control
                                    disabled={country ? false : true}
                                    placeholder="City"
                                    aria-label="City"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { dispatch(searchCity(e.target.value)); setCity(e.target.value); setIsCityValid(false) }}
                                    value={city}
                                    maxLength={250}
                                />
                            </InputGroup>
                            {city ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        {
                            allCitiesFiltered.length !== 0 ?
                                <div className='position-relative'>
                                    <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                        {
                                            allCitiesFiltered && allCitiesFiltered.map((el, index) => {
                                                return <div key={`accounteditanagraphic2-${index}`} className='px-2 rounded-5 myCursor my-1' onClick={() => { setCity(el.name); dispatch(clearSearchCity()); setIsCityValid(true) }}>{el.name}</div>
                                            })
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </div>

                    <div className="mb-3 d-flex gap-2 align-items-center">
                        <InputGroup >
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-map-fill"></i></InputGroup.Text>
                            <Form.Control
                                disabled={country && city ? false : true}
                                placeholder="State/Province"
                                aria-label="State/Province"
                                aria-describedby="basic-addon1"
                                onChange={(e) => { setState(e.target.value) }}
                                value={state}
                                maxLength={45}
                            />
                        </InputGroup>
                        {state ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                    </div>

                    {
                        isInvoiceAnagraphic ?
                            null :
                            <div className="mb-3 d-flex gap-2 align-items-center">
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1"><i className="bi bi-phone-fill"></i></InputGroup.Text>
                                    <DropdownButton disabled={country ? false : true} className="mb-3 w-100" variant='dark' id="dropdown-basic-button" title={phoneCode ? phoneCode : "select a prefix"}>
                                        {
                                            phoneCodes.map((el, index) => {
                                                return <Dropdown.Item className='text-wrap' key={`accounteditanagraphic3-${index}`} onClick={() => setPhoneCode(el.dial_code)}>{el.dial_code} ({el.name})</Dropdown.Item>
                                            })
                                        }
                                    </DropdownButton>
                                    <Form.Control
                                        disabled={country ? false : true}
                                        type='number'
                                        placeholder="Phone"
                                        aria-label="Phone"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                        maxLength={45}
                                    />
                                </InputGroup>
                                {phone && phone.length > 5 ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                            </div>
                    }

                    { //COUNTRY EXCLUDE & SELL SELECTION
                        !isInvoiceAnagraphic &&
                        <div className='mb-5'>
                            <hr className='my-4' />
                            {
                                userData && userData.manufacturer ?
                                    <div>{/* MANUFACTURERS */}
                                        <h5 className='mt-4 mb-3 fw-bold text-center'><i className="bi bi-globe-americas"></i> Geographic visibility </h5>
                                        <div className={`${openInfo ? "" : "line-clampBeta"} w-100 myLightGrayColor myCursor`} onClick={() => setOpenInfo(!openInfo)}><i className="bi bi-info-circle-fill myLightGrayColor"></i> This choice will be considered only as an account preference. During the product creation and editing stages, this preference can be changed for each individual published product, in order to allow you to make individual products visible (or invisible) in different countries.</div>
                                        <div className='d-flex justify-content-center'>
                                            <div className='d-flex text-center myBgDarkGray rounded-5 px-1 py-1 mt-3'>
                                                <h6 className={`${excludeCountry ? "myBgYellow text-dark" : ""} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(true) }}>Exclude country</h6>
                                                <h6 className={`${excludeCountry ? "" : "myBgYellow text-dark"} rounded-5 px-3 py-1 fw-bold myCursor myFontSize14px`} onClick={() => { setExcludeCountry(false) }}>Select country</h6>
                                            </div>
                                        </div>
                                        <div className='display-5 my-3 d-flex justify-content-center'>{excludeCountry ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}</div>
                                        <div className='d-flex justify-content-center'>
                                            {
                                                excludeCountry ?
                                                    <p>The <b>Exclude country</b> allows you to make your products visible to Dealers located worldwide, <b>except for Dealers located in those countries you want to exclude from the list.</b></p>
                                                    :
                                                    <p>The <b>Select country</b> allows you to make your products visible to Dealers located <b>only in those countries you select from the list.</b></p>
                                            }
                                        </div>
                                        {
                                            excludeCountry ?
                                                <>
                                                    <div className='d-flex justify-content-start'>
                                                        {
                                                            addArea ?
                                                                <div className="my-2 w-100">
                                                                    <div className="mb-3 d-flex gap-2 align-items-center">
                                                                        <InputGroup >
                                                                            <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                            <Form.Control variant='dark' id="dropdown-basic-button"
                                                                                placeholder='type a country'
                                                                                onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                                onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                            />
                                                                        </InputGroup>
                                                                    </div>
                                                                    {
                                                                        sellExclCountryDropdownOpen ?
                                                                            <div className='position-relative'>
                                                                                <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                                    {
                                                                                        countryFiltered && countryFiltered.map((el, index) => {
                                                                                            return <div className='px-2 rounded-5 myCursor my-1' key={`accounteditanagraphic4-${index}`} onClick={() => { dispatch(setSellingAreaExcluded(el)); setAddArea(false); setSellExclCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                :
                                                                <div className='d-flex gap-4 align-items-center flex-wrap my-3 myCursor'>
                                                                    <Form><Form.Check defaultChecked={sellWholeWorld ? "checked" : ""} type="switch" id="custom-switch" label="I want to sell worldwide" onClick={() => { setSellWholeWorld(!sellWholeWorld); dispatch(clearSellingAreaExcluded()); dispatch(clearSellingAreaSelected()) }} /></Form>
                                                                    <div className={`${sellWholeWorld ? "disabledPlus" : null} d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>exclude a country</div>
                                                                </div>
                                                        }

                                                    </div>
                                                    {sellingAreaExcludedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Excluded country/ies</h4> : null}
                                                    <div className='text-light d-flex flex-wrap gap-2'>
                                                        {
                                                            sellingAreaExcludedISO && sellingAreaExcludedISO.map((el, index) => {
                                                                return (
                                                                    <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' key={`accounteditanagraphic5-${index}`}>
                                                                        {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaExcluded(el)) }}></i>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='d-flex justify-content-start'>
                                                        {
                                                            addArea ?
                                                                <div className="my-2 w-100">
                                                                    <div className="mb-3 d-flex gap-2 align-items-center">
                                                                        <InputGroup >
                                                                            <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                            <Form.Control variant='dark' id="dropdown-basic-button"
                                                                                placeholder='type a country'
                                                                                onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                                onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                            />
                                                                        </InputGroup>
                                                                    </div>
                                                                    {
                                                                        sellExclCountryDropdownOpen ?
                                                                            <div className='position-relative'>
                                                                                <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                                    {
                                                                                        countryFiltered && countryFiltered.map((el, index) => {
                                                                                            return <div className='px-2 rounded-5 myCursor my-1' key={`accounteditanagraphic6-${index}`} onClick={() => { dispatch(setSellingAreaSelected(el)); setAddArea(false); setSellExclCountryDropdownOpen(false); setSellWholeWorld(false) }}>{el.split(":")[0]}</div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                :
                                                                <div className={`d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>select a country</div>
                                                        }

                                                    </div>
                                                    {sellingAreaSelectedISO.length ? <h4 className='text-light fw-light mb-2 mt-4'>Selected country/ies</h4> : null}
                                                    <div className='text-light d-flex flex-wrap gap-2'>
                                                        {
                                                            sellingAreaSelectedISO && sellingAreaSelectedISO.map((el, index) => {
                                                                return (
                                                                    <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' key={`accounteditanagraphic7-${index}`}>
                                                                        {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delSellingAreaSelected(el)) }}></i>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    :
                                    <div>{/* DEALERS */}
                                        <h5 className='mt-4 fw-bold'><i className="bi bi-globe-americas"></i> Distribution area</h5>
                                        <div className={`${openInfo ? "" : "line-clampBeta"} w-100 myLightGrayColor my-3 myCursor`} onClick={() => setOpenInfo(!openInfo)}><i className="bi bi-info-circle-fill myLightGrayColor"></i> This choice will be considered only as an account preference. During the product creation and editing stages, this preference can be changed for each individual published product, in order to allow you to make individual products visible (or invisible) in different countries.</div>

                                        <div className='mb-4'>
                                            {
                                                addArea ?
                                                    <div>
                                                        <div className="mb-3 d-flex gap-2 align-items-center">
                                                            <InputGroup >
                                                                <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                                                                <Form.Control variant='dark' id="dropdown-basic-button"
                                                                    placeholder='type a country'
                                                                    onChange={(e) => { dispatch(searchCountry(e.target.value)) }}
                                                                    onClick={() => { setSellExclCountryDropdownOpen(true) }}
                                                                />
                                                            </InputGroup>
                                                        </div>
                                                        {
                                                            sellExclCountryDropdownOpen ?
                                                                <div className='position-relative'>
                                                                    <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                                                        {
                                                                            countryFiltered && countryFiltered.map((el, index) => {
                                                                                return <div className='px-2 rounded-5 myCursor my-1' key={`accounteditanagraphic8-${index}`} onClick={() => { dispatch(setDistributionArea(el)); setAddArea(false); setSellExclCountryDropdownOpen(false) }}>{el.split(":")[0]}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    : <div className={`${distributionAreaISO.length ? "disabledPlus" : ""} d-flex align-items-center flex-wrap gap-2 text-warning myCursor`} onClick={() => { setAddArea(true) }}><div className='myBgWhite plusDistributionArea d-flex align-items-center justify-content-center border' ><i className="bi bi-plus-lg text-dark"></i></div>add a country</div>
                                            }
                                        </div>


                                        <div className='text-light d-flex gap-2'>
                                            {
                                                distributionAreaISO && distributionAreaISO.map((el, index) => {
                                                    return (
                                                        <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5 distAreaCountry' key={`accounteditanagraphic9-${index}`}>
                                                            {el.split(":")[0]}<i className="bi bi-trash-fill text-danger ms-2 myCursor" onClick={() => { dispatch(delDistributionArea(el)) }}></i>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    }

                    {
                        isInvoiceAnagraphic && !invoiceAnagraphic.length && <p className='text-center'>This is the first time you pay with this account. Congratulations! <br />
                            To proceed you need to insert correct invoice data and click to +Add.  </p>
                    }

                    <div className='d-flex justify-content-center pt-4 pb-3'>
                        {
                            isLoading || loadingInvoice ?
                                <div>
                                    <Spinner variant='secondary' animation="border" />
                                </div>
                                :
                                <h5>
                                    <Button variant="primary" disabled={city && country && !countryDropdownOpen && !sellExclCountryDropdownOpen && isCityValid ? false : true} className={`bi ${invoiceAnagraphic.length || !isInvoiceAnagraphic ? "bi-check-lg" : "bi-plus-lg"} myCursor px-4 rounded-5`} onClick={() => { formCheck(); localStorage.removeItem("editId") }}> {invoiceAnagraphic.length || !isInvoiceAnagraphic ? "Confirm" : "Add"}</Button>
                                </h5>
                        }
                    </div>

                    <div>
                        <h6 className='text-center mt-3 text-danger'>{errorMessage}</h6>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default AccountEditAnagraphic