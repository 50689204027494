import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardFavouritesAnnouncement from './CardFavouritesAnnouncement';
import { getUserOutletFunc } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';
import { useNavigate } from 'react-router';
import decodeToken from '../functions/decodeToken';
import CardPenRejAnnouncementGrid from './CardPenRejAnnouncementGrid';
import CardPenRejAnnouncementLine from './CardPenRejAnnouncementLine';
import { nanoid } from 'nanoid';
import { Spinner } from 'react-bootstrap';
import serverResponseManagement from '../functions/serverResponseManagement';
import { Link } from 'react-router-dom';
import { setUserConnectionsData, setQuickActionOverlay } from '../states/generalState';
import {Button} from 'react-bootstrap';
import { updateTutorialFunc } from '../states/tutorialState';
import TutorialFavourites from './_Tutorials/TutorialFavourites';


const _Favourites = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const scrollRef = useRef();

    const locStorView = localStorage.getItem("typeOfView");
    const [typeOfView, setTypeOfView] = useState(locStorView ? +locStorView : 2);
    const tkn = decodeToken();
    const token = localStorage.getItem("token");
    const userConnectionIds = [];
    const [userConnectionIdsLength, setUserConnectionIds] = useState(0);
    const [tutorialFavourites, setTutorialFavourites] = useState(false);
    const [tutorialFavouritesStep, setTutorialFavouritesStep] = useState(0);
    const tutorialData = useSelector((state) => state.tutorial.tutorialData);

    useEffect(() => {

        if (token) {
            dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                        if (favouritesData && !favouritesData.length) {
                            dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }))
                                .then((res) => {
                                    serverResponseManagement(res, navigate, dispatch);
                                })
                        }
                    }
                })
        } else {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (favouritesData && !favouritesData.length) {
            scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, []);

    useEffect(() => {
        favouritesData && favouritesData.length && favouritesData.map((el) => {
            if (!userConnectionIds.includes(el.idOwner)) {
                userConnectionIds.push(el.idOwner)
            }
        });
        setUserConnectionIds(userConnectionIds.length)
    }, [favouritesData]);

    //Tutorial Functions
    const updateTutorial = () => {
        dispatch(updateTutorialFunc({ payload: { favourites: 0 }, token }))
            .then((res) => {
                if (res.payload.statusCode === 200) {
                    window.location.reload()
                }
            })
    };

    useEffect(() => {
        if (tutorialData && tutorialData.favourites) {
            setTutorialFavourites(true)
        }
    }, [tutorialData]);



    return (

        <div className='position-relative'>

            {
                tutorialFavourites ?
                    <div className='position-fixed start-0 d-flex justify-content-center w-100 myZindex2 myOverflowY myMaxVh100 py-5 mt-2'>
                        <div className='text-light border border-secondary shadow myBgTransparentVeryHigh top-0 m-3 mt-4 rounded-5 p-3 py- text-center h-100 d-flex flex-column gap-5 myMaxW500'>
                            <TutorialFavourites step={tutorialFavouritesStep} />
                            {
                                tutorialFavouritesStep === 0 ?
                                    <Button className='rounded-5 w-100' onClick={() => { setTutorialFavouritesStep(1) }}>Next</Button>
                                    :
                                    tutorialFavouritesStep === 1 ?
                                        <div className='d-flex gap-2'>
                                            <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialFavouritesStep(0) }}>Back</Button>
                                            <Button className='rounded-5 w-75' onClick={() => { setTutorialFavouritesStep(2) }}>Next</Button>
                                        </div>
                                        :
                                        <div className='d-flex gap-2'>
                                            <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialFavouritesStep(1) }}>Back</Button>
                                            <Button className='rounded-5 w-75' onClick={updateTutorial}>Next</Button>
                                        </div>
                            }
                        </div>
                    </div>
                    : null
            }

            <div className='pt-2 mb-4 text-light' ref={scrollRef}>
                <div>
                    <div className='d-flex justify-content-around align-items-start gap-3'>

                        <Link to={'/connections'} className='noLinkStyle' onClick={() => { dispatch(setUserConnectionsData(userConnectionIds)) }}>
                            <div className='d-flex flex-column align-items-center myCursor'>
                                <span className='position-relative usersHeartIcon '>
                                    <i className="bi bi-person-circle"></i>
                                    <i className="bi bi-heart-fill myFucsiaRed position-absolute"></i>
                                </span>
                                <div>{userConnectionIdsLength} <i className="bi bi-link-45deg"></i></div>
                            </div>
                        </Link>

                        <div>
                            <div className='d-flex justify-content-center align-items-center gap-2 mt-2' style={{ fontSize: "1.5rem" }}>
                                <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 2 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(2); localStorage.setItem("typeOfView", "2") }}><i className="bi bi-columns-gap myCursor"></i></div>
                                <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 0 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(0); localStorage.setItem("typeOfView", "0") }}><i className="bi bi-grid myCursor"></i></div>
                                <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 1 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(1); localStorage.setItem("typeOfView", "1") }}><i className="bi bi-list-task myCursor"></i></div>
                            </div>
                            <div className='d-flex align-items-center justify-content-center mt-1'>
                                <div>{favouritesData.length} favourites</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                favouritesData && favouritesData.length !== 0 ?
                    <div>
                        {
                            typeOfView === 2 ?
                                <div className='pb-5 mb-5'>
                                    <div className='pt-3  w-100 h-100 verticalGrid' onClick={() => { dispatch(setQuickActionOverlay({ status: false, id: null })) }} >
                                        {
                                            favouritesData && favouritesData.map((el, index) => {
                                                return <CardPenRejAnnouncementGrid key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} index={index} />
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 mt-3 pb-5 px-1'>
                                    {
                                        favouritesData && favouritesData.map((el) => {
                                            if (typeOfView === 0) {
                                                return <CardFavouritesAnnouncement key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} superuser={false} />
                                            } if (typeOfView === 1) {
                                                return <CardPenRejAnnouncementLine key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} />
                                            }
                                        })
                                    }
                                </div>
                        }
                    </div>
                    :
                    isLoading ?
                        <div className='d-flex justify-content-center mt-5'><Spinner animation="border" /></div>
                        : null

            }
        </div >

    )
}

export default _Favourites