import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    isLoading: false,
    tutorialData: null
}


export const getMyTutorialFunc = createAsyncThunk(
    "getMyTutorialFunc",
    async (input) => {
        const { idOwner, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getmytutorial/${idOwner}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);

export const createTutorialFunc = createAsyncThunk(
    "createTutorialFunc",
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/createtutorial`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);

export const updateTutorialFunc = createAsyncThunk(
    "updateTutorialFunc",
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updatetutorial`, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);


const tutorialSlice = createSlice({
    name: "tutorialSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //getMyTutorialFunc
        builder.addCase(getMyTutorialFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getMyTutorialFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tutorialData = action.payload && action.payload.data
        });
        builder.addCase(getMyTutorialFunc.rejected, (state) => {
            state.isLoading = false
        });
        //createTutorialFunc
        builder.addCase(createTutorialFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(createTutorialFunc.fulfilled, (state) => {
            state.isLoading = false
        });
        builder.addCase(createTutorialFunc.rejected, (state) => {
            state.isLoading = false
        });
        //updateTutorialFunc
        builder.addCase(updateTutorialFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(updateTutorialFunc.fulfilled, (state) => {
            state.isLoading = false
        });
        builder.addCase(updateTutorialFunc.rejected, (state) => {
            state.isLoading = false;
        });

    }

})

export const { } = tutorialSlice.actions;
export default tutorialSlice.reducer