import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsersByIdFunc } from '../states/loginState';
import UserConnectionCard from './UserConnectionCard';
import decodeToken from '../functions/decodeToken';
import serverResponseManagement from '../functions/serverResponseManagement';
import { getUserOutletFunc } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';
import { useNavigate } from 'react-router';
import { setUserConnectionsData } from '../states/generalState';

const _UserConnections = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userConnectionsData = useSelector((state) => state.general.userConnectionsData);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const allUsersData = useSelector((state) => state.login.allUsersData);
    const userConnectionIds = [];

    const scrollRefConnection = useRef();

    const token = localStorage.getItem("token");
    const tkn = decodeToken();

    useEffect(() => {
        if (favouritesData && favouritesData.length) {
            favouritesData.map((el) => {
                if (!userConnectionIds.includes(el.idOwner)) {
                    userConnectionIds.push(el.idOwner)
                }
            });
            dispatch(setUserConnectionsData(userConnectionIds))
        }
    }, [favouritesData])

    useEffect(() => {
        if (userConnectionsData && userConnectionsData.length) {
            dispatch(getAllUsersByIdFunc({ idSet: userConnectionsData, token }))
        } else {
            if (token) {
                dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                    .then((res) => {
                        serverResponseManagement(res, navigate, dispatch);
                        if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                            if (favouritesData && !favouritesData.length) {
                                dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }))
                                    .then((res) => {
                                        serverResponseManagement(res, navigate, dispatch);
                                    })
                            }
                        }
                    })
            } else {
                navigate('/login');
            }
        }
    }, [userConnectionsData]);

    useEffect(() => {
        scrollRefConnection.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);


    return (
        <div className='text-light d-flex justify-content-center pb-5'>
            <div className='myMaxW700 w-100 p-3 px-0 pb-5' ref={scrollRefConnection}>
                {
                    allUsersData && allUsersData.length ? allUsersData.map((el, index) => {
                        return <div key={`userconnections1-${index}`}><UserConnectionCard singleData={el} index={index} /></div>
                    })
                    :
                    <h1 className='mt-5 fw-light text-center text-secondary'>No connections yet</h1>
                }
            </div>
        </div>
    )
}

export default _UserConnections