import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const PaypalPayment = ({ product }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const announcementPayload = useSelector((state) => state.myStore.announcementPayload);

    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: product.currency,
        intent: "capture",
    };

    const apiUrl = process.env.REACT_APP_SERVER_ADDRESS

    const createOrder = async () => {
        // Order is created on the server and the order id is returned

        return fetch(`${apiUrl}/api/orders`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
                product: {
                    description: "pricePackage.description",
                    cost: product.price
                }
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    };

    const onApprove = async (data) => {
        // Order is captured on the server and the response is returned to the browser
        const response = await fetch(`${apiUrl}/api/orders/${data.orderID}/capture`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                orderID: data.orderID
            })
        })
        const res = await response.json()
        if (res.status === "COMPLETED") {
            /* dispatch(postCreateAnnouncementFunc(announcementPayload)); */
            /* navigate(`/paymentsuccess/${product.type}`) */
            console.log(res);
        } else {
            navigate("*")
        }
        return res

    };


    return (
        <div className='d-flex justify-content-center'>
            <div className='w-100 myMaxW700 p-4'>
                <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                    />
                </PayPalScriptProvider>
            </div>
        </div>
    )
}

export default PaypalPayment