import React from 'react';
import MainLayout from '../Layouts/MainLayout';

const ErrorPage = () => {
  return (
    <MainLayout>
      <h1 className='myLightGrayColor fw-light pt-5 text-center'><i className="bi bi-search"></i> Error 404<br/>Page not found!</h1>
    </MainLayout>
  )
}

export default ErrorPage