import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    error: "",
    slotsData: []
};

export const getSlotsByIdOwnerFunc = createAsyncThunk(
    "getSlotsByIdOwnerFunc",
    async (input) => {
        const { idOwner, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getslotsbyidowner/${idOwner}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    }
);

export const createSlotsFunc = createAsyncThunk(
    "createSlotsFunc",
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/createnewslots`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    }
);



const slotSlice = createSlice({
    name: 'slotSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSlotsByIdOwnerFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getSlotsByIdOwnerFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.slotsData = action.payload.data
        });
        builder.addCase(getSlotsByIdOwnerFunc.rejected, (state) => {
            state.isLoading = false
        });
        builder.addCase(createSlotsFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(createSlotsFunc.fulfilled, (state, action) => {
            state.isLoading = false
        });
        builder.addCase(createSlotsFunc.rejected, (state) => {
            state.isLoading = false
        });
    }
});

const { } = slotSlice.actions;
export default slotSlice.reducer