import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    invoiceAnagraphic: [],
    error: "",
    isEditingInvoiceAnagraphic: false,
    reloadAnagraphic: false
};

export const getInvoiceAnagrapicFunc = createAsyncThunk(
    'getInvoiceAnagrapicFunc',
    async (input) => {
        const { idOwner, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getinvoiceanagraphic/${idOwner}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error);
        }
    }
);

export const createInvoiceAnagrapicFunc = createAsyncThunk(
    'createInvoiceAnagrapicFunc',
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/createinvoiceanagraphics`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error);
        }
    }
)

export const updateInvoiceAnagrapicFunc = createAsyncThunk(
    'updateInvoiceAnagrapicFunc',
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updateinvoiceanagraphics`, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error);
        }
    }
)

const invoiceAnagraphicSlice = createSlice({
    name: "invoiceAnagraphicSlice",
    initialState,
    reducers: {
        setInvoiceEditingState: (state, action) => {
            state.isEditingInvoiceAnagraphic = action.payload
        },
        setReloadAnagraphic: (state, action) => {
            state.reloadAnagraphic = action.payload
        }
    },
    extraReducers: (builder) => {
        //getInvoiceAnagrapicFunc
        builder.addCase(getInvoiceAnagrapicFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getInvoiceAnagrapicFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.invoiceAnagraphic = action.payload && action.payload.data ? action.payload.data : [] 
        });
        builder.addCase(getInvoiceAnagrapicFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = "An error occurred"
        });
        //createInvoiceAnagrapicFunc
        builder.addCase(createInvoiceAnagrapicFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(createInvoiceAnagrapicFunc.fulfilled, (state) => {
            state.isLoading = false
        });
        builder.addCase(createInvoiceAnagrapicFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = "An error occurred"
        });
        //updateInvoiceAnagrapicFunc
        builder.addCase(updateInvoiceAnagrapicFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(updateInvoiceAnagrapicFunc.fulfilled, (state) => {
            state.isLoading = false
        });
        builder.addCase(updateInvoiceAnagrapicFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = "An error occurred"
        });
    }
})

export const { setInvoiceEditingState, setReloadAnagraphic } = invoiceAnagraphicSlice.actions;
export default invoiceAnagraphicSlice.reducer