import React, { useEffect } from 'react';
import _Sidebar from '../components/_Sidebar';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';



const MainLayout = ({ children }) => {
    const loading1 = useSelector((state) => state.signin.loading);

    return (
        <div className='myBgAbsBlack' style={{ height: "100%" }}>
            {
                loading1 ? <div className='w-100 d-flex align-items-center justify-content-center' style={{ position: "fixed", height: "100%", zIndex: "103", backgroundColor: "rgb(255,255,255,.7)" }}>
                    <Spinner animation="border" role="status" variant='warning'></Spinner>
                </div>
                    : null
            }

            <main style={{ minHeight: "100vh" }}>
                {children}
            </main>
            <_Sidebar />
        </div>
    )
}

export default MainLayout