import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import _UserConnections from '../components/_UserConnections';

const _UserConnectionsPage = () => {
    return (
        <MainLayout>
            <_UserConnections />
        </MainLayout>
    )
}

export default _UserConnectionsPage