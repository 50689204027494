import React from 'react'; 
import { useNavigate } from 'react-router';

const OutletTimerExplanation = () => {

    const iconsSize = {
        fontSize: "30px"
    };

    const navigate = useNavigate();


    return (
        <div className='mt-5 w-100 myMaxW700 d-flex flex-column gap-2 px-2 pb-5'>
            <h3 className='myYellowColor'>The Market needs to recharge its energy!</h3>
            <h6 className='fw-light'>
                In the meantime go to your <b className='fw-bold'><i className='bi bi-heart-fill'> Favourites </i></b> (bottom bar) and click on the top-left corner icon
            </h6>
            <div>
                <span className='position-relative usersHeartIcon'>
                    <i className="bi bi-person-circle"></i>
                    <i className="bi bi-heart-fill myFucsiaRed position-absolute"></i>
                </span>
                <div>n° <i className="bi bi-link-45deg"></i></div>
            </div>

            <hr className='w-100' />

            <div className='d-flex gap-3 align-items-center text-start'>
                <i className="bi bi-link-45deg fw-bold myCursor" style={iconsSize} onClick={()=>navigate('/connections')}></i>
                <h6 className='fw-light'>
                    In that section you will find the list of your <i className="bi bi-link-45deg fw-bold"> Connections</i>. Any connection is a user which have published one or more products in the Market and who you liked at least one of its product.
                </h6>
            </div>

            <hr className='w-100' />

            <div className='d-flex gap-3 align-items-center mt-3 text-start'>
                <i className='bi bi-heart-fill myCursor' style={iconsSize} onClick={()=>navigate('/favourites')}></i>
                <h6 className='fw-light'>
                    Now you can take a look at all your connections products and leave as many <b className='fw-bold'>Likes</b> as you desire.
                </h6>
            </div>

            <hr className='w-100' />

            <div className='d-flex gap-3 align-items-center mt-3 text-start'>
                <i className='bi bi-chat-dots-fill myCursor' style={iconsSize} onClick={()=>navigate('/chat')}></i>
                <h6 className='fw-light'>
                    Under the <i className='bi bi-chat-dots-fill fw-bold'> Chat</i> is available a dedicated chat for any product you liked. Use it for establish new business relationship.
                </h6>
            </div>

            <hr className='w-100' />

        </div>
    )
}

export default OutletTimerExplanation