import React from 'react'
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useNavigate } from 'react-router-dom';


const PopupApprovalGuide = () => {

    const navigate = useNavigate();
    
    return (
        <div className='py-5 px-3'>
            <div className='d-flex flex-column gap-4 text-light p-3 pt-4 rounded-5 myBgDark222 text-center myMaxW500'>
                <h2 >Under Approval <Spinner className='ms-2' animation="grow" /></h2>
                <h6 className='myBgChat text-dark rounded-5 p-2'>Your product has been published correctly <i className="bi bi-check-circle-fill ms-2"></i></h6>
                <ol className='text-start'>
                    <li className='mb-3'><h6>Your product will be reviewed by our Team. </h6></li>
                    <li className='mb-3'><h6>You will receive an email as soon as your product is approved. </h6></li>
                    <li className='mb-3'>
                        <h6>If your product is rejected, you will receive an email explicitly explaining the reasons for the rejection. Follow the rejection management guide at the link below. </h6>
                        <Link className='myFucsiaRed' to={'/rejectionmanagementguide'}>Rejection Management Guide</Link>
                    </li>
                </ol>
                <Button className='rounded-5' onClick={()=>navigate('/announcements')}>OK</Button>
            </div>
        </div>
    )
}

export default PopupApprovalGuide